import { FC, useEffect, useState } from "react"

import { FileExcelOutlined, LoadingOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import DropshippingColumns from "./components/DropshippingColumns"
import { dropshippingApi } from "../../common/api/endpoints/DropshippingApi"
import { API_WS_URL } from "../../common/api/urls"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { showErrorMessage, showSuccessMessage } from "../../utils/ui"

const Dropshipping: FC = () => {
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ productId, setProductId ] = useState<number | null>(null)
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })
  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const [ columns, setColumns ] = useState<ColumnsType<any>>(() => {
    const savedColumns = localStorage.getItem("savedDropshippingColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el: { key: string }) => el.key)
      const sortedDefaultColumns = [ ...DropshippingColumns({ setIsAddOpen, setProductId }) ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return DropshippingColumns({ setIsAddOpen, setProductId })
  })

  const [ activeMarketplace, setActiveMarketplace ] = useState<number | null>(null)

  const { isLoading, itemData, pagesData,isUpdate } = useTypedSelector((state) => state.dropshipping)
  const { dropshippingGetList, setDropshippingUpdate } = useActions()
  const [ isSocket,setIsSocket ] = useState(false)
  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-dropshipping"]?.status === true) {
            setIsSocket(true)
            setDropshippingUpdate(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }

  useEffect(()=>{
    connectWebSocket()
  },[])
  useEffect(()=>{
    if(isUpdate){
      dropshippingGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters,
      },isSocket)
      setIsSocket(false)
      setDropshippingUpdate(false)
    }
  },[ isUpdate,isSocket ])
  useEffect(() => {
    dropshippingGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters,
    },isSocket)
  }, [ filters, pagesData.currentPage, pagesData.pageSize, sorting ])

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const currentSort: { [x: string]: string } = sorter.order
      ? {
        [sorter.columnKey]: sorter.order === "ascend" ? "ASC" : "DESC",
      }
      : { arrival_date: "DESC" }

    setSorting(currentSort)
    setFilters({
      ...filters,
      page: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const handleMarketplaceFilter = (marketplaceId: number) => {
    if (activeMarketplace === marketplaceId) {
      setFilters(defaultItemsFilters)
      setActiveMarketplace(null)
    } else {
      setFilters({
        ...defaultItemsFilters,
        marketplace_id: marketplaceId,
      })
      setActiveMarketplace(marketplaceId)
    }
  }

  const refreshTable = () => {
    dropshippingGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters,
    })
  }

  const handleImportExcel = async () => {
    try{
      await dropshippingApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  return (
    <MainLayout pageTitle="Дропшиппинг">
      <div className="page-wrapper">
        <PageHead titleStyles={{ fontWeight: "600" }} title="Дропшиппинг, закупки из заказов" extra={<div></div>} />
        <div className="page-content">
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: activeMarketplace === 1 ? "#4096ff" : "",
                  color: activeMarketplace === 1 ? "#fff" : "",
                }}
                onClick={() => handleMarketplaceFilter(1)}
              >
                <img
                  alt={"Маркетплейс"}
                  style={{ borderRadius: "50%", width: "20px", marginRight: "6px" }}
                  src={marketPlacePictureMap[1]}
                />
                WILDBERRIES
              </Button>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: activeMarketplace === 2 ? "#4096ff" : "",
                  color: activeMarketplace === 2 ? "#fff" : "",
                }}
                onClick={() => handleMarketplaceFilter(2)}
              >
                <img
                  alt={"Маркетплейс"}
                  style={{ borderRadius: "50%", width: "20px", marginRight: "6px" }}
                  src={marketPlacePictureMap[2]}
                />
                OZON
              </Button>
            </div>
            <Button
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={handleImportExcel}
              style={{
                backgroundColor: "#28a745",
                borderColor: "#28a745",
                borderRadius: "4px",
                color: "#fff",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              СКАЧАТЬ В EXCEL
            </Button>
          </Space>
          <Table
            title={() => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => setIsManageColumnsVisible(true)}
                >
                  <MenuOutlined
                    style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
                  />
                  СТОЛБЦЫ
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={() => {
                      setColumns(DropshippingColumns({ setIsAddOpen, setProductId }))
                      showSuccessMessage("Порядок успешно возвращен.")
                      localStorage.setItem(
                        "savedDropshippingColumns",
                        JSON.stringify(DropshippingColumns({ setIsAddOpen, setProductId }))
                      )
                    }}
                  >
                    ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
                  </Button>
                </div>
              </div>
            )}
            columns={columns}
            dataSource={itemData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            }}
            scroll={{ x: 1300 }}
            pagination={{
              position: [ "bottomLeft" ],
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: pagesData?.currentPage || 1,
              total: pagesData.total,
              showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} карточек`,
            }}
            onChange={handleTableChange}
            size="small"
            bordered
          />
          <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} productId={productId} onSuccess={refreshTable} />
        </div>

        {isManageColumnsVisible && (
          <ColumnSettings
            setVisible={setIsManageColumnsVisible}
            defaultColumns={DropshippingColumns({ setIsAddOpen, setProductId })}
            columns={columns}
            setColumns={setColumns}
          />
        )}
      </div>
    </MainLayout>
  )
}

export default Dropshipping
