import React, { useEffect } from "react"

import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Modal, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"


const PriceTab = () => {
  const { dataPrice, isLoading } = useTypedSelector((state) => state.monitoring)
  const { monitoringFetchPriceData, monitoringDownloadFile, monitoringDeleteFile } = useActions()

  useEffect(() => {
    monitoringFetchPriceData()
  }, [])

  const priceColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Название файла",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              monitoringDownloadFile(record.id, record.name || "report.csv")
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить файл?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  monitoringDeleteFile(record.id, () => {
                    monitoringFetchPriceData()
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    },
  ]

  return (
    <Table
      dataSource={dataPrice}
      columns={priceColumns}
      scroll={{ x: 870 }}
      loading={{
        spinning: isLoading,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
      }}
      pagination={false}
      bordered
    />
  )
}

export default PriceTab
