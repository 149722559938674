import { currentUserSliceActions } from "./currentUserSlice"
import { authApi } from "../../../common/api/endpoints/authApi"
import { handleResponseError } from "../../../common/api/middleware"
import { clearStorage } from "../../../utils/auth"
import { openLink } from "../../../utils/ui"
import { TAppDispatch } from "../../store"
import { appActions } from "../app/actions"
import { authActions } from "../auth/actions"

export const currentUserActions = {
  ...currentUserSliceActions,

  getCurrentUserData: () => async (dispatch: TAppDispatch) => {
    dispatch(appActions.setAppLoading(true))
    authApi
      .getUserData()
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(
            currentUserActions.currentUserSetData({
              id: data.id,
              name: data.name,
              username: data.username,
              role: data.role,
              telegram: data.telegram || false
            })
          )
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
        dispatch(authActions.setAuthClear())
        dispatch(currentUserActions.currentUserClearData())
        dispatch(authActions.setIsAuth(false))

        clearStorage()
      })
      .finally(() => {
        dispatch(appActions.setAppLoading(false))
      })
  },

  connectUserTelegram: (onFinish?: () => void) => async (dispatch: TAppDispatch) => {
    authApi.connectTelegram()
      .then((response) => {
        if (response.status === 200 && response.data?.telegram_url) {
          openLink(response.data.telegram_url)
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        onFinish && onFinish()
      })
  },
  removeUserTelegram: (userId:number,onFinish?: () => void) => async (dispatch: TAppDispatch) => {
    authApi.removeTelegram(userId)
      .then((response) => {
        if (response.status === 200 && response.data?.telegram_url) {
          openLink(response.data.telegram_url)
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(currentUserActions.getCurrentUserData())
        onFinish && onFinish()
      })
  }
}
