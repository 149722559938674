import React, { useState, useEffect } from "react";
import { Select } from "antd";

const { Option } = Select;

interface Option {
  id: number;
  name: string;
}

interface AutoWidthSelectProps {
  options: Option[];
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  allowClear?: boolean;
  style?: React.CSSProperties;
}

const AutoWidthSelect: React.FC<AutoWidthSelectProps> = ({
  options,
  value,
  onChange,
  style = {},
  placeholder = "",
  allowClear = false,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const span = document.createElement("span");
    document.body.appendChild(span);
    span.style.visibility = "hidden";
    span.style.whiteSpace = "nowrap";

    let max = 0;
    options.forEach((item) => {
      span.innerText = item.name;
      max = Math.max(max, span.offsetWidth);
    });

    document.body.removeChild(span);
    setMaxWidth(max);
  }, [options]);

  return (
    <Select
      value={value}
      allowClear={allowClear}
      onChange={onChange}
      placeholder={placeholder}
      style={{ ...(dropdownOpen && { width: maxWidth + 60 }), ...style }}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
    >
      {options.map((option) => (
        <Option
          key={option.id}
          value={option.id}
          style={option.id === -1 ? { background: "#00a2e8", color:"white" } : {}}
        >
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default AutoWidthSelect;
