import qz, { CreatePrinterInput, PrintData } from "qz-tray"

import { showErrorMessage } from "./ui"
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = (reader.result as string).split(",")[1]
      resolve(base64String)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
export const printWithQZTray = async (pdfBlob:any) => {
  const blob = new Blob([ pdfBlob ], { type: "application/pdf" })
  const base64String = await blobToBase64(blob)

  try {
    const isActive = qz.websocket.isActive()
    if(!isActive){
      qz.security.setCertificatePromise(function(resolve, reject) {
        resolve()
      })
      qz.security.setSignaturePromise((toSign) => {
        return (resolve, reject) => {
          resolve()
        }
      })
      await qz.websocket.connect()
    }
    const printers = await qz.printers.find()
    console.log(printers)
    if((Array.isArray(printers) && printers?.length === 0) || printers?.length === 0){
      showErrorMessage("Проверьте подключение к принтеру.")
      await qz.websocket.disconnect()
      return "refresh"
    }
    if(Array.isArray(printers)){
      printers.forEach((printer) => {
        const config = qz.configs.create(printer as CreatePrinterInput)
        const data = [ { type: "pixel", format: "pdf", data: base64String,flavor:"base64" } ] as  PrintData[]

        qz.print(config, data).catch(console.error)
      })
    }else{
      const config = qz.configs.create(printers as CreatePrinterInput)
      const data = [ { type: "pixel", format: "pdf", data: base64String,flavor:"base64" } ] as  PrintData[]

      qz.print(config, data).catch(console.error)
    }
  } catch (err) {
    showErrorMessage("Проверьте подключение к принтеру.")
    console.error("QZ Tray connection failed:", err)
    return "refresh"
  }
}
