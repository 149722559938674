import axios from "axios";
import { axiosConfigAuth, handleResponseError } from "../middleware";
import { API_DROPSHIPPING_ADD_SUPLIERS, API_DROPSHIPPING_EXPORT_EXCEL, API_DROPSHIPPING_POST_TABLE_DATA } from "../urls";
import { TAppDispatch } from "../../../redux/store";
import { showErrorNotification } from "../../../utils/ui";

export const dropshippingApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_DROPSHIPPING_POST_TABLE_DATA, filters, axiosConfigAuth());
  },

  edit: async(data: any) => {
    return axios.post(API_DROPSHIPPING_ADD_SUPLIERS, data, axiosConfigAuth());
  },


  downloadExcel: async (filters:any) => {
    try {
      const response = await axios.post(
        API_DROPSHIPPING_EXPORT_EXCEL,
        filters,
        {
          ...axiosConfigAuth(),
           responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, "0")
      const day = String(now.getDate()).padStart(2, "0")

      let fileName = `export_dropshipping_orders_${year}_${month}_${day}.csv`

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      // const dispatch = (() => {}) as TAppDispatch
      showErrorNotification("Нет данных для скачивания.")
      console.error("Error downloading Excel file:", error)
    }
  },
}
