import { stockFBSSliceActions } from "./stockFBSSlice"
import { stockFBSMarketApi } from "../../../common/api/endpoints/stockFBSMarketApi"
import { stockSuppliersApi } from "../../../common/api/endpoints/stockSuppliersApi"
import { handleResponseError } from "../../../common/api/middleware"
import { AddCookies } from "../../../types/api"
import { showSuccessMessage } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const stockFBSActions = {
  ...stockFBSSliceActions,
  stockFBSGetCompanies: () => (dispatch: TAppDispatch) => {
    dispatch(stockFBSActions.setItemListsLoading(true))

    stockFBSMarketApi
      .fetchCompanies()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockFBSActions.setStockFBSCompanies(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockFBSActions.setItemListsLoading(false))
      })
  },
  stockFBSGetImportResults: () => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .fetchImportResults()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockFBSActions.setStockFBSImportResults(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSGetSuppliersImportResults: () => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .fetchSuppliersImportResults()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockFBSActions.setStockFBSSuppliersResults(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSRemoveSticker: (orderId:number) => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .removeSticker(orderId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockFBSActions.setStockFBSUpdate(true))
          showSuccessMessage("Стикер успешно удален.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSUpdateSticker: (data:any) => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .updateSticker(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockFBSActions.setStockFBSUpdate(true))
          showSuccessMessage("Стикер успешно обновлен.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSAddSupply: (data:any) => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .addSupply(data)
      .then((response) => {
        if (response.status === 200) {
          // dispatch(stockFBSActions.setStockFBSUpdate(true))
          showSuccessMessage("Поставка успешно добавлена.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSUpdateSupply: (data:any) => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .updateSupply(data)
      .then((response) => {
        if (response.status === 200) {
          // dispatch(stockFBSActions.setStockFBSUpdate(true))
          showSuccessMessage("Поставка успешно сменена.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockFBSGetList: (filters: any,isSocket=false) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(stockFBSActions.setItemLoading(true))

    stockFBSMarketApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(stockFBSActions.setStockFBSTableData(data.rows))
          dispatch(stockFBSActions.setStockFBSFilters({
            ...(data?.partners && { partners:data.partners }),
            ...(data?.supplies && { supplies:data.supplies }),
            ...(data?.supplies_for_transfer && { supplies_for_transfer:data.supplies_for_transfer }),
            ...(data?.statuses && { statuses:Object.values(data.statuses) }),
            ...(data?.delivery_date && { delivery_date:data.delivery_date }),
            ...(data?.marketplace_count && { marketplace_count:data.marketplace_count }),

          }))
          dispatch(
            stockFBSActions.setStockFBSPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(stockFBSActions.setItemLoading(false))
      })
  },
  stockFBSAddCookies: (
    data: AddCookies,
    onFinish: () => void,
    onFormError: (errors: {[key: string]: string}) => void
  ) =>
    (dispatch: TAppDispatch) => {
      stockFBSMarketApi
        .addCookies(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data)
          }
          handleResponseError(dispatch, err)
        })

    },
  stockFBSCheckCookies: () => (dispatch: TAppDispatch) => {
    stockFBSMarketApi
      .checkCookies()
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage("Куки рабочие")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
}
