import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { Routes, Route, Navigate } from "react-router-dom"

import {
   roles,
} from "./common/constants/roles"
import { routeNames } from "./common/constants/routes"
import { useActions } from "./common/hooks/useActions"
import { useAllowedRoles } from "./common/hooks/useAllowedRoles"
import { useTypedSelector } from "./common/hooks/useTypedSelector"
import Login from "./pages/auth/login"
import Dropshiping from "./pages/dropshiping"
import ErrorPage from "./pages/error"
import Export from "./pages/export"
import Home from "./pages/home"
import ImportsPage from "./pages/imports/ImportsPage"
import ItemCards from "./pages/item-cards"
import MonitoringPage from "./pages/monitoring/MonitoringPage"
import NotFound from "./pages/not-found"
import Partners from "./pages/partners"
import Prices from "./pages/prices"
import Sbermarket from "./pages/sbermarket"
import StockFBS from "./pages/stock-fbs"
import CollectOrders from "./pages/tasks/collect-orders/CollectOrders"
import Staff from "./pages/staff"
import Supplier from "./pages/supplier"
import Stock from "./pages/stock"
import StockSuppliers from "./pages/stock-suppliers"
import TelegramNotifications from "./pages/telegram-notifications"
import Suppliers from "./pages/suppliers"
import MonitoringSettingsPage from "./pages/settings/monitoring/MonitoringSettingsPage"
import ProductsImportPage from "./pages/products-import/ProductsImportPage"
import DropshippingSupplier from "./pages/dropshipping-supplier"
import Inventarization from "./pages/inventarization/Inventarization";

const AppRouter: FC = () => {
  const isAdmin = useAllowedRoles([ roles.ADMIN_ROLE ])
  const isSeniorManager = useAllowedRoles([ roles.SENIOR_MANAGER_ROLE ])
  const isGeneralManager = useAllowedRoles([ roles.GENERAL_MANAGER ])
  const isManager = useAllowedRoles([ roles.MANAGER_ROLE ])
  const isBuyerSbermarket = useAllowedRoles([ roles.BUYER_SBERMARKET ])
  const isBuyerSadovod = useAllowedRoles([ roles.BUYER_SADOVOD ])
  const isCollectorScanner = useAllowedRoles([ roles.COLLECTOR_SCANNER ])
  const isAssembler = useAllowedRoles([ roles.ASSEMBLER ])

  const { isGlobalLoading } = useTypedSelector((state) => state.app)
  const { isAuth } = useTypedSelector((state) => state.auth)
  const { getCurrentUserData, setAppLoading } = useActions()


  const getRedirectPath = () => {
    let routeName
    if (isAdmin || isGeneralManager || isSeniorManager || isManager) {
      routeName = routeNames.itemCards
    } else if (isBuyerSbermarket || isAssembler) {
      routeName = routeNames.stockFBS
    } else if (isBuyerSadovod) {
      routeName = routeNames.dropshipping
    } else if (isCollectorScanner) {
      routeName = routeNames.collectOrders
    } else {
      routeName = routeNames.notFound
    }
    return routeName
  }

  const redirectPath = getRedirectPath()


  useEffect(() => {
    if (isAuth) {
      setAppLoading(true)
      setTimeout(() => getCurrentUserData(), 100)
    } else {
      setAppLoading(false)
    }
  }, [ isAuth ])

  return isGlobalLoading ? (
    <div className="global-loading-container">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <Routes>
      {isAuth ? (
        <>
          <Route path="/" element={<Navigate to={redirectPath} replace />} />

          {(isAdmin || isGeneralManager) && (
            <>
              <Route path={routeNames.home} element={<Home />} />
              <Route path={routeNames.monitoring} element={<MonitoringSettingsPage />} />
              <Route path={routeNames.imports} element={<ImportsPage />} />
              <Route path={routeNames.telegramNotifications} element={<TelegramNotifications />} />
              <Route path={routeNames.itemCards} element={<ItemCards />} />
              <Route path={`${routeNames.dropshippingSupplier}/:supplierId`} element={<DropshippingSupplier />} />
              <Route path={routeNames.importProducts} element={<ProductsImportPage />} />
              <Route path={routeNames.prices} element={<Prices />} />
              <Route path={routeNames.stockFBS} element={<StockFBS />} />
              <Route path={routeNames.stockSuppliers} element={<StockSuppliers />} />
              <Route path={routeNames.sbermarket} element={<Sbermarket />} />
              <Route path={`${routeNames.stockSuppliers}/:supplierId`} element={<Supplier />} />
              <Route path={routeNames.dropshipping} element={<Dropshiping />} />
              <Route path={routeNames.collectOrders} element={<CollectOrders />} />
              <Route path={routeNames.partners} element={<Partners />} />
              <Route path={routeNames.staff} element={<Staff />} />
              <Route path={routeNames.suppliers} element={<Suppliers />} />
              <Route path={routeNames.monitoringMain} element={<MonitoringPage />} />
              <Route path={routeNames.stock} element={<Stock />} />
              <Route path={routeNames.inventorization} element={<Inventarization />} />
              <Route path={routeNames.export} element={<Export />} />
            </>
          )}

          {isSeniorManager && (
            <>
              <Route path={routeNames.home} element={<Home />} />
              <Route path={routeNames.monitoring} element={<MonitoringSettingsPage />} />
              <Route path={routeNames.imports} element={<ImportsPage />} />
              <Route path={routeNames.telegramNotifications} element={<TelegramNotifications />} />
              <Route path={routeNames.itemCards} element={<ItemCards />} />
              <Route path={routeNames.importProducts} element={<ProductsImportPage />} />
              <Route path={routeNames.prices} element={<Prices />} />
              <Route path={routeNames.stockFBS} element={<StockFBS />} />
              <Route path={routeNames.stockSuppliers} element={<StockSuppliers />} />
              <Route path={`${routeNames.stockSuppliers}/:supplierId`} element={<Supplier />} />
              <Route path={routeNames.sbermarket} element={<Sbermarket />} />
              <Route path={routeNames.dropshipping} element={<Dropshiping />} />
              <Route path={routeNames.partners} element={<Partners />} />
              <Route path={routeNames.staff} element={<Staff />} />
              <Route path={routeNames.monitoringMain} element={<MonitoringPage />} />
              <Route path={routeNames.stock} element={<Stock />} />
              <Route path={routeNames.export} element={<Export />} />
            </>
          )}

          {isManager && <Route path={routeNames.itemCards} element={<ItemCards />} />}
          {isBuyerSbermarket && <Route path={routeNames.stockFBS} element={<StockFBS />} />}
          {isBuyerSadovod && <Route path={routeNames.dropshipping} element={<Dropshiping />} />}
          {isCollectorScanner && <Route path={routeNames.collectOrders} element={<CollectOrders />} />}
          {isAssembler && <Route path={routeNames.stockFBS} element={<StockFBS />} />}

          <Route path={routeNames.notFound} element={<NotFound />} />
          <Route path={routeNames.error} element={<ErrorPage />} />
          <Route path="*" element={<Navigate to={routeNames.notFound} replace />} />
        </>
      ) : (
        <>
          <Route path={routeNames.login} element={<Login />} />
          <Route path={routeNames.notFound} element={<NotFound />} />
          <Route path={routeNames.error} element={<ErrorPage />} />
          <Route path="*" element={<Navigate to={routeNames.login} replace />} />
        </>
      )}
    </Routes>
  )
}

export default AppRouter
