import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined, PlusOutlined, UserAddOutlined
} from "@ant-design/icons"
import { Button, Input, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import ColorTag from "../../common/components/color-tag/ColorTag"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { errorTagColor, successTagColor } from "../../common/constants/colors"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"

const Staff: FC = () => {
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ editId, setEditId ] = useState("")

  const { staffData, isLoading } = useTypedSelector((state) => state.staff)
  const { staffGetList, staffDelete } = useActions()

  const [ staffTempData, setStaffTempData ] = useState(staffData)

  useEffect(() => {
    staffGetList()
  }, [])

  useEffect(() => {
    setStaffTempData(staffData)
  }, [ staffData ])

  const staffColumns: ColumnsType<any> = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Логин",
      dataIndex: "username",
      key: "username",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Менеджер",
      dataIndex: "manager",
      key: "manager",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Группа WhatsApp",
      dataIndex: "whatsapp_group",
      key: "whatsapp_group",
      render: (value: any) => (
        <Tooltip placement="topLeft" title={value.name}>
          <a href={value.link} target="_blank">
            {value.name}
          </a>
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false
      },
      width: 150
    },
    {
      title: "Телефон WhatsApp",
      dataIndex: "whatsapp_phone",
      key: "whatsapp_phone",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Телефон ВБ",
      dataIndex: "phone_binding_wb",
      key: "phone_binding_wb",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Партнеры",
      dataIndex: "partners",
      key: "partners",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (val) => (
        <ColorTag color={val === "Активный" ? successTagColor : errorTagColor}>
          {val}
        </ColorTag>
      ),
      width: 150
    },
    {
      title: "Статус в группе WhatsApp",
      dataIndex: "whatsapp_group_status",
      key: "whatsapp_group_status",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Реквизиты для ЗП",
      dataIndex: "requisites",
      key: "requisites",
      ellipsis: {
        showTitle: false
      },
      width: 160,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата принятия на работу",
      dataIndex: "date_of_employment",
      key: "date_of_employment",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 90,
      render: (value, record) => (
        <Space direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditId(record.id)
              setIsEditOpen(true)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить сотрудника?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  staffDelete(record.id, () => {
                    staffGetList()
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]

  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    const _data = staffData.filter((val: any) => {
      return (
        `${val.manager}`.toLowerCase().includes(searchString) ||
        `${val.name}`.toLowerCase().includes(searchString) ||
        `${val.whatsapp_group.name}`.toLowerCase().includes(searchString) ||
        `${val.whatsapp_phone}`.toLowerCase().includes(searchString) ||
        `${val.phone_binding_wb}`.toLowerCase().includes(searchString) ||
        `${val.partners}`.toLowerCase().includes(searchString) ||
        `${val.role}`.toLowerCase().includes(searchString) ||
        `${val.status}`.toLowerCase().includes(searchString) ||
        `${val.whatsapp_group_status}`.toLowerCase().includes(searchString) ||
        `${val.requisites}`.toLowerCase().includes(searchString) ||
        `${val.date_of_employment}`.toLowerCase().includes(searchString) ||
        `${val.comment}`.toLowerCase().includes(searchString)
      )
    })

    setStaffTempData(_data)
  }

  return (
    <MainLayout pageTitle="Сотрудники">
      <div className="page-wrapper">
        <PageHead
          title="Сотрудники"
          extra={
            <div>
            </div>
          }
        />

        <div className="page-content">
          <Space>
            <Input
              placeholder="Поиск по таблице..."
              onChange={(e) => searchTable(e.target.value)}
              style={{ width: 300, marginBottom: 20 }}
              allowClear
            />
          </Space>
          <Space style={{ marginTop:"-20px" }}>
            <Button type="primary" style = {{background:"#00a2e8"}} onClick={() => setIsAddOpen(true)}>
              <UserAddOutlined />
              Добавить сотрудника
            </Button>
          </Space>
          <Table
            columns={staffColumns}
            dataSource={staffTempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            size="small"
            bordered
          />

          <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
          <EditModal
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            editId={editId}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default Staff
