import React, { FC, useEffect, useMemo, useRef, useState } from "react"

import {
  DeleteOutlined,
  LoadingOutlined,
  MenuOutlined,
  SaveOutlined,
} from "@ant-design/icons"
import { Button, Table, Tooltip, Form, Input, Image, Space, Popconfirm, InputNumber } from "antd"
import { ColumnsType } from "antd/es/table"
import { useParams } from "react-router-dom"


import { API_WS_URL } from "../../common/api/urls"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { DropshippingSuppliersPagesData } from "../../redux/slices/dropshippingSuppliers/types"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap, vk_image } from "../../utils/data"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

const Supplier: FC = () => {
  const { supplierId } = useParams()

  const [ isTransferToDeliveryLoading, setIsTransferToDeliveryLoading ] =
    useState(false)
  const [ selectedIds, setSelectedIds ] = useState<number[]>([])
  const [ selectedSupplierNames, setSelectedSupplierNames ] = useState<string>("")


  const { isLoading, pagesData,isUpdate } = useTypedSelector((state) => state.dropshippingSupplier)
  const {
    companies,
  } =
      useTypedSelector((state) => state.stockFBS)
  const {
    dropshippingSupplierGetList,
    stockFBSGetCompanies,
    dropshippingSupplierDelete,
    addProductPurchased,
    setDropshippingSupplierUpdate,
    setSupplierDropshippingPagesData
  } =
    useActions()
  const [ columns, setColumns ] = useState<ColumnsType<any>>([])
  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<number[]>([])
  const [ isSocket, setIsSocket ] = useState(false)
  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-dropshipping"]?.status === true) {
            setDropshippingSupplierUpdate(true)
            setIsSocket(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }

  useEffect(()=>{
    connectWebSocket()
  },[])


  useEffect(() => {
    const selectedNames = companies
      .filter(company => selectedIds.includes(company.id))
      .map(company => company.name)
      .join(", ")
    setSelectedSupplierNames(selectedNames)
  }, [ selectedIds, companies ])
  useEffect(()=>{
    if(isUpdate){
      dropshippingSupplierGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: { arrival_date: "DESC" },
        filters: { supplier_id: supplierId, company_id: selectedIds.length > 0 ? selectedIds : undefined },
      },isSocket)
      setDropshippingSupplierUpdate(false)
      setIsSocket(false)
    }
  },[ isUpdate,isSocket ])

  useEffect(() => {
    stockFBSGetCompanies()
    dropshippingSupplierGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: { arrival_date: "DESC" },
      filters: { supplier_id: supplierId, company_id: selectedIds.length > 0 ? selectedIds : undefined },
    },isSocket)
  }, [ selectedIds, supplierId, pagesData.currentPage, pagesData.pageSize ])


  useEffect(() => {
    if (pagesData?.rows) {
      setColumns(defaultColumns)
    }
  }, [ pagesData ])

  useEffect(() => {
    const selectedNames = companies
      .filter(company => selectedIds.includes(company.id))
      .map(company => company.name)
      .join(", ")
    setSelectedSupplierNames(selectedNames)
  }, [ selectedIds, companies ])

  const handleResetColumns = () => {
    setColumns(defaultColumns)
    showSuccessMessage("Порядок успешно возвращен.")
  }

  const handleClickDelete = async (supplierId: any, productId: any) => {
    try {
      console.log("Попытка удаления: ", supplierId, productId)
      dropshippingSupplierDelete(supplierId, productId)
      console.log("Удаление прошло успешно")
    } catch (error) {
      console.error("Ошибка при удалении поставщика", error)
      showErrorMessage("Ошибка при удалении поставщика")
    }
  }


  const handleSave = async (record: any) => {
    try {
      const { product_id, quantity, real_price } = record
      if (!product_id || !quantity || !real_price) {
        throw new Error("Missing product data for saving.")
      }
      const dataToSave = {
        products: [
          {
            product_id,
            supplier_id: supplierId,
            quantity,
            real_price,
          },
        ],
      }


      await addProductPurchased(dataToSave)
      showSuccessMessage("Все изменения сохранены.")

    } catch (error:any) {
      console.log(error)
      const isMissingData = error?.message === "Missing product data for saving."
      isMissingData && showErrorNotification("Не заполнено поле реальная цена или кол-во!")
    }
  }

  const handleSaveAll = async () => {
    try {
      const productsToSave = selectedRowKeys.map((key) => {
        const row = pagesData?.rows?.find((el)=>el?.product_id === key)

        const { product_id, quantity, real_price } = row
        if (!product_id || !quantity || !real_price) {
          throw new Error("Missing product data for saving.")
        }

        return {
          product_id,
          supplier_id: supplierId,
          quantity,
          real_price,
        }
      })


      if (productsToSave.length > 0) {
        const dataToSave = {
          products: productsToSave,
        }

        await addProductPurchased(dataToSave)

        showSuccessMessage("Все изменения сохранены.")
        setSelectedRowKeys([])

      }
    } catch (error:any) {
      console.log(error)
      const isMissingData = error?.message === "Missing product data for saving."
      isMissingData && showErrorNotification("Не заполнено поле реальная цена или кол-во!")
    }
  }


  const handleRowEdit = (record: any, field: string, value: any) => {
    const updatedPagesData:DropshippingSuppliersPagesData = {
      ...pagesData,
      rows: pagesData
        ?.rows?.map((item) =>
          item.product_id === record.product_id
            ? { ...item, [field]: value }
            : item
        ) }

    setSupplierDropshippingPagesData(updatedPagesData)
  }

  const defaultColumns = [
    {
      title: "ID",
      dataIndex: "product_id",
      key: "product_id",
      sorter: true,
      render: (val:any) => (
        <span>{val}</span>
      )
    },
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (val: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            alt="Фото"
            style={{ width: "60px", borderRadius: "8px" }}
            src={val}
          />
        </div>
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (val:any) => (
        <span>{val}</span>
      )
    },
    {
      title: "Ссылки",
      dataIndex: "links",
      key: "product_url",
      render: (val: any) => (
        <Space style={{ display: "flex", justifyContent: "center" }}>
          {val.product_url && (
            <a href={val.product_url} target="_blank" rel="noopener noreferrer">
              <img alt="Продукт" src={marketPlacePictureMap[val.marketplace_id]} style={{ borderRadius: "50%", width: "24px", marginRight: "8px", marginLeft:"8px" }} />
            </a>
          )}
          {val.vk_url && (
            <a href={val.vk_url} target="_blank" rel="noopener noreferrer">
              <img alt="VK" style={{ borderRadius: "50%", width: "24px" }} src={vk_image} />
            </a>
          )}
        </Space>
      ),
    },
    {
      title: "Количество",
      dataIndex: "quantity",
      key: "quantity",
      sorter: true,
      render: (value: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputNumber
            min={1}
            precision={0}
            value={Math.ceil(Number(value))}
            onChange={(val) => val !== null && handleRowEdit(record, "quantity", Math.ceil(Number(val)))}
          />
        </div>
      ),
    },
    {
      title: "Закупочная цена",
      dataIndex: "purchase_price",
      key: "purchase_price",
      sorter: true,
      render: (val: any, record: any) => (
        <span style={{ display: "flex", justifyContent: "center" }}>{val}</span>
      )
    },
    {
      title: "Реальная цена",
      dataIndex: "real_price",
      key: "real_price",
      sorter: true,
      render: (value: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputNumber
            min={0}
            step={0.01}
            value={Number(value)}
            onChange={(val) => val !== null && handleRowEdit(record, "real_price", val)}
          />
        </div>
      ),
    },
    {
      title: "Время с момента заказа",
      dataIndex: "arrival_date",
      key: "arrival_date",
      sorter: true,
      render: (val: any) => (
        <strong style={{ color: `#${val.color}` }}>{val.value}</strong>
      ),
    },
    {
      title: "Артикул",
      dataIndex: "sku",
      key: "sku",
      sorter: true,
      render: (val:any) => (
        <span>{val}</span>
      )
    },
    {
      title: "Штрихкод",
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
      render: (val:any) => (
        <span>{val}</span>
      )
    },
    {
      title: "Действия",
      key: "operation",
      width: 100,
      render: (val: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "10px",
              rowGap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Tooltip placement="topLeft" title="Сохранить">
              <Button
                className="blueButton"
                type="primary"
                shape="circle"
                onClick={() => handleSave(record)}
                icon={<SaveOutlined />}
                style={{ ...buttonStyle, padding: 0, width: "20px", height: "30px" }}
              />
            </Tooltip>
            <Popconfirm
              title={
                <span>
                  Подтверждаете удаление связи<br />поставщика и товара?
                </span>
              }
              onConfirm={() => handleClickDelete(supplierId!, record.product_id)}
              okText="Удалить"
              cancelText="Отмена"
            >
              <Button
                className="blueButton"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                style={{ ...buttonStyle, padding: 0, width: "20px", height: "30px" }}
              />
            </Popconfirm>
          </div>
        )
      },
    }
  ]


  return (
    <>
      <MainLayout pageTitle={"Новый заказ поставщику"}>
        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title={"Новый заказ поставщику "}
            extra={<div></div>}
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px",justifyContent:"space-between",marginRight: "24px" }}>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            </div>
          </div>
          <div style={{
            display: "grid",
            gridTemplateColumns: "150px 250px",
            gap: "16px",
            marginTop: "16px",
            marginLeft: "24px"
          }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>НАЗВАНИЕ</span>
            <Input style={{ width: "100%" }} defaultValue={pagesData.header.name} value={pagesData.header.name} />

            <span style={{ fontWeight: 600, fontSize: 16 }}>ТЕЛЕФОН</span>
            <Input style={{ width: "100%" }} defaultValue={pagesData.header.phone == null ? "Нет" : pagesData.header.phone} value={pagesData.header.phone == null ? "Нет" : pagesData.header.phone} />

            <span style={{ fontWeight: 600, fontSize: 16 }}>ОРГАНИЗАЦИЯ</span>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Input
                style={{ width: "100%" }}
                value={selectedSupplierNames}
                readOnly
                placeholder="Выбранные поставщики"
              />
              <AdvancedDropdown
                items={companies}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            </div>
          </div>
          <div className="page-content">
            <div
              style={{
                display: "flex",
                columnGap: "20px",
                alignItems: "center",
                rowGap: "10px",
                flexWrap: "wrap",
                position:"relative"
              }}
            >
            </div>
            <Table
              title={() => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={() => setIsManageColumnsVisible(true)}>
                    <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}/>
                      СТОЛБЦЫ</Button>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }} onClick={() => {
                      handleResetColumns()
                    }}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
                  </div>
                </div>

              )}
              columns={columns}
              dataSource={pagesData?.rows || []}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedKeys: React.Key[]) => {
                  setSelectedRowKeys(selectedKeys as number[])
                },
              }}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
              }}
              rowKey={"product_id"}
              pagination={{
                position: [ "bottomLeft" ],
                showSizeChanger: true,
                pageSizeOptions: [ "10", "25", "50", "100" ],
                defaultCurrent: 1,
                defaultPageSize: 10,
                current: pagesData?.currentPage || 1,
                total: pagesData?.total,
                showTotal: (total) => `Всего строк: ${total}`,
              }}
              size="small"
              bordered
            />
          </div>
          {isManageColumnsVisible && (
            <ColumnSettings
              setVisible={setIsManageColumnsVisible}
              defaultColumns={defaultColumns}
              columns={columns}
              setColumns={setColumns}
            />
          )}

          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 200,
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
            }}
          >
            <Button
              type="primary"
              onClick={handleSaveAll}
              disabled={selectedRowKeys.length === 0}
              className="blueButton"
              style={{ marginLeft:"25px" }}
            >
          Сохранить
            </Button>
          </div>
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

export default Supplier
