import React, { FC, useState } from "react"

import {  CloseOutlined, EllipsisOutlined } from "@ant-design/icons"
import { Modal, Row, Col, Checkbox, Input, Button } from "antd"
import { ReactSortable } from "react-sortablejs"
import {showErrorMessage} from "../../../utils/ui";
import {projectIcons} from "../../icons/projectIcons";


const ColumnSettings: FC<any> = ({ defaultColumns,columns,setColumns,setVisible }) => {
  const [ items, setItems ] = useState(defaultColumns)
  const [ selected, setSelected ] = useState<any>(columns)
  const [ searchTerm, setSearchTerm ] = useState("")

  const handleCheck = (item:any, isChecked:any) => {
    if (isChecked) {
      setSelected([ ...selected, item ])
    } else {
      selected.length > 5 && setSelected(selected.filter((i:any) => i.key !== item.key))
      if(!(selected.length > 5)){
        showErrorMessage("Минимальное количество колонок - 5")
      }
    }
  }


  const removeSelect = (key:any) => {
    setSelected(selected.length > 5 ? selected.filter((item:any) => item.key !== key) : selected)
    if(!(selected.length > 5)){
      showErrorMessage("Минимальное количество колонок - 5")
    }
  }
  const handleCloseForm = () => {
    const selectedKeys = selected.map((el:{key:string}) => el.key)
    const sortedDefaultColumns = [ ...defaultColumns ].sort((a, b) => {
      return selectedKeys.indexOf(a.key) - selectedKeys.indexOf(b.key)
    })
    const newColumns = sortedDefaultColumns.filter(el => selectedKeys.includes(el.key))
    setColumns(newColumns)
    localStorage.setItem("savedColumns", JSON.stringify(newColumns))
    setVisible(false)
  }
  const handleReset = () => {
    setColumns(defaultColumns)
    setSelected(defaultColumns)
  }

  return (
    <Modal
      title="Настройка размещения столбцов"
      open={true}
      onOk={handleCloseForm}
      onCancel={handleCloseForm}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{className:"blueButton"}}
      destroyOnClose
      width={"50%"}
      maskClosable={false}
    >
      <div className="modal-settings">
        <Row gutter={16} style={{ marginTop:"20px" }}>
          <Col span={12}>
            <Input
              placeholder="Поиск..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom:"10px" }}
            />
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {items
                .filter((item:{title:string}) => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item:{key:string,title:string}) => (
                  <div key={item.key}>
                    <Checkbox
                      onChange={(e) => handleCheck(item, e.target.checked)}
                      checked={selected.some((i:any) => i.key === item.key)}
                    >
                      {item.title}
                    </Checkbox>
                  </div>
                ))}
            </div>
            <Button style={{ marginTop:"10px" }} onClick={handleReset}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
          </Col>
          <Col span={12}>
            <div style={{ maxHeight: "440px", overflowY: "auto",overflowX:"hidden" }}>
              <ReactSortable
                list={selected.map((item:{}) => ({ ...item, chosen: true }))}
                setList={setSelected}
                animation={200}
                delayOnTouchStart={true}
                delay={2}
              >
                {selected.map((item:{key:string,title:string}) => (
                  <div className="drag-item" key={item.key}>
                    <span className="drag-handler">
                      <EllipsisOutlined rotate={90} />
                      <span className="drag-label">
                        {item.title}
                      </span>
                    </span>
                    <div onClick={() => removeSelect(item.key)} className="drag-remove">
                      <CloseOutlined />
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default ColumnSettings
