import React, { FC, useEffect, useState } from "react"

import { EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Space, Switch, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import EditModal from "./EditModal"
import MainLayout from "../../../common/components/layouts/MainLayout"
import PageHead from "../../../common/components/page-header/PageHeader"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"

const MonitoringSettingsPage: FC = () => {

  const [ openEditModal, setOpenEditModal ] = useState(false)
  const [ editData, setEditData ] = useState({
    id: 0,
    name:"",
    minutes: "",
    comment: "",
    orders_count: undefined,
  })

  const { data, isLoading } = useTypedSelector((state) => state.monitoringSettings)
  const { monitoringSettingsFetchData, monitoringSettingsUpdateStatus } = useActions()

  const changeStatus = (id: string, value: boolean) => {
    monitoringSettingsUpdateStatus(id, value, () => monitoringSettingsFetchData())
  }

  useEffect(() => {
    monitoringSettingsFetchData()
  }, [])

  const staffColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Интервал, мин",
      dataIndex: "minutes",
      key: "minutes",
    },
    {
      title: "Кол-во заказов",
      dataIndex: "orders_count",
      key: "orders_count",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={() => changeStatus(record.id, value !== 1)}
        />
      )
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditData({
                id: record.id,
                name:record.name,
                minutes: record.minutes,
                comment: record.comment,
                orders_count: record.orders_count || undefined,
              })
              setOpenEditModal(true)
            }}
          />
        </Space>
      )
    },
  ]

  return (
    <MainLayout pageTitle="Мониторинг">
      <div className="page-wrapper">
        <PageHead
          title="Мониторинг"
        />
        <div className="page-content">
          <Table
            dataSource={data}
            columns={staffColumns}
            scroll={{ x: 870 }}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            pagination={false}
            bordered
          />

          <EditModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            id={editData.id}
            name={editData.name}
            minutes={editData.minutes}
            comment={editData.comment}
            orders_count={editData.orders_count}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default MonitoringSettingsPage
