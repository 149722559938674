import React, { FC, useEffect, useMemo, useState } from "react"

import {
  CalendarOutlined,
  CarOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  ShoppingCartOutlined, UndoOutlined
} from "@ant-design/icons"
import { AnyAction, Dispatch } from "@reduxjs/toolkit"
import { Button, Collapse, Space, Table, Tooltip, Image, Popconfirm } from "antd"
import { ColumnsType } from "antd/es/table"
import type { TableRowSelection } from "antd/es/table/interface"
import { AxiosError } from "axios"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import AddSupplier from "./components/AddSupplier"
import FilterTable from "./components/Filter"
import SuppliersModal from "./components/SuppliersModal"
import { stockFBSMarketApi } from "../../common/api/endpoints/stockFBSMarketApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import Accordion from "../../common/components/accordion/Accordion"
import ColorTag from "../../common/components/color-tag/ColorTag"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import ImportResultAlert from "../../common/components/import-result-alert/ImportResultAlert"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import AutoWidthSelect from "../../common/components/selects/AutoWidthSelect"
import { defaultStockFBSFilters } from "../../common/constants/filters"
import { routeNames } from "../../common/constants/routes"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { TAppDispatch } from "../../redux/store"
import { OrderStatus, PrintLabel, TransferToDelivery, UpdateOrderStatus } from "../../types/api"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

const Sbermarket: FC = () => {
  const [ filters, setFilters ] = useState<any>(defaultStockFBSFilters)
  const [ sorting, setSorting ] = useState<{[x: string]: string}>({
    created_at: "DESC"
  })
  const { filters: filtersFromResponse } = useTypedSelector((state) => state.stockFBS)

  const [ isVisible, setIsVisible ] = useState(true)
  const [ isSuppliesVisible,setIsSuppliesVisible ] = useState(false)
  const [ isSocket, setIsSocket ] = useState(false)

  const { isLoading,
    isLoadingLists,
    isUpdate,
    itemData,
    pagesData,
    companies,
    importResults,
    suppliersResults
  } =
      useTypedSelector((state) => state.stockFBS)
  const {
    stockFBSGetList,
    stockFBSGetImportResults,
    stockFBSGetSuppliersImportResults,
    setStockFBSUpdate,
    stockFBSGetCompanies,
    stockFBSRemoveSticker,
    stockFBSUpdateSticker,
    stockFBSUpdateSupply
  } =
      useActions()
  const dispatch = useDispatch()
  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ addSupplierState,setAddSupplierState ] = useState<{visible: boolean,id: null | number | undefined , partner_id: null | number | undefined,name: string | undefined,marketplace_id:number | null | undefined}>({ visible:false,id:null,partner_id:null,name:"",marketplace_id:null })
  const [ checkSupplierState,setCheckSupplierState ] = useState<{visible: boolean,id: null | number | undefined , msg: string}>({ visible:false,id:null,msg:"" })
  const [ partnersState,setPartnersState ] = useState<{values:number[],type:number}>({
    values:[],
    type:1
  })
  const handlePartnerIdsChange = (type:number,values:number[]) => {
    setPartnersState({ type,values })
  }
  const navigate = useNavigate()
  const [ isActionLoading,setIsActionLoading ] = useState(false)
  const [ isTransferToDeliveryLoading,setIsTransferToDeliveryLoading ] = useState(false)
  const [ transferSuppliers,setTransferSuppliers ] = useState<number | null>(null)
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<React.Key[]>([])
  const [ isDisabledDownloadButton,setDisabledDownloadButton ] = useState(false)
  const handleDownloadSticker = async (order_id:number,isDownload=true) => {
    try{
      setDisabledDownloadButton(true)
      await stockFBSMarketApi.downloadSticker(order_id,isDownload)
    }catch(err){
      showErrorMessage("Произошла ошибка, повторите попытку позже.")
      console.log(err)
    }finally{
      setDisabledDownloadButton(false)
    }
  }
  const handleDownloadLabel = async (order_id:number,isDownload=true) => {
    try{
      setDisabledDownloadButton(true)
      await stockFBSMarketApi.downloadLabel(order_id,isDownload)
    }catch(err){
      // showErrorMessage("Произошла ошибка, повторите попытку позже.")
      console.log(err)
    }finally{
      setDisabledDownloadButton(false)
    }
  }
  const handleDownloadExcel = async () => {
    try{
      await stockFBSMarketApi.downloadExcel(filters)
    }catch(err:any){
      handleResponseError(dispatch,err)
      console.log(err)
    }
  }
  const handleUpdateSticker = async (record:{id:number,order_id:number,marketplace_id:number,partner_id:number}) => {
    try{
      const data = {
        id:record?.id,
        order_id:record?.order_id,
        partner_id:record?.partner_id,
        marketplace_id:record?.marketplace_id
      }
      stockFBSUpdateSticker(data)
    }catch(err){
      console.log(err)
    }
  }
  const handleCreateNewSupplier = (record:{id?:number | null,order_id?:number | null,marketplace_id?:number | null,partner_id?:number | null}) => {
    setAddSupplierState({
      visible:true,
      id:record?.id,
      partner_id:record?.partner_id,
      marketplace_id:record?.marketplace_id,
      name:""
    })
  }
  const handleSupplyChange = (id:number,supply_id:number) => {
    stockFBSUpdateSupply({
      id,
      supply_id
    })
  }


  const StockFBSColumns: ColumnsType<any> = [
    {
      title: "Статус",
      dataIndex: "status_info",
      key: "status_info",
      sorter: true,
      align:"center",
      width: 220,
      render: (val) => {
        const color = `#${val?.color}` || "red"
        return <ColorTag color={color}><span style={{ color:"white",fontWeight:"600",padding:"12px",fontSize:"14px" }}>{val.name}</span></ColorTag>
      }
    },
    {
      title: "Ошибка",
      dataIndex: "error_msg",
      key: "error_msg",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      align:"center",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "28px" }}
          src={marketPlacePictureMap[val]}/>
      ),
    },
    {
      title: "Номер заказа",
      dataIndex: "order_id",
      key: "order_id",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Товар",
      dataIndex: "product_info",
      key: "product_info",
      sorter: false,
      ellipsis: {
        showTitle: false,
      },
      width: 470,
      render: (val) => (
        <div style={{ display:"flex" }}>
          <div style={{ height:"100%" }}>
            <Image alt={"Основное фото"} style={{ width: "60px" }}
              src={val?.photo_url || "https://brilliant24.ru/files/cat/template_01.png"}/>
          </div>

          <div style={{ display: "flex", marginLeft: "6px", rowGap: "6px", flexDirection: "column",height:"100%" }}>
            <span>
              <strong>1</strong> шт
            </span>
            <span>
              <strong>
                {val?.purchase_price}
              </strong> руб
            </span>
          </div>
          <div style={{ display: "flex", marginLeft: "6px", columnGap: "6px", flexWrap: "wrap",flexDirection:"column" }}>
            <strong style={{ whiteSpace: "break-spaces" }}>{val?.product_name}</strong>
            <span style={{ whiteSpace:"break-spaces",maxWidth: "95%" }}>Цвет: {val?.color}</span>
            <Space style={{ marginTop:"4px" }}>
              {
                val?.marketplace_id &&
                    <a href={val?.product_url} target={"_blank"}>
                      <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "25px", height: "25px" }}
                        src={marketPlacePictureMap[val?.marketplace_id]}/>
                    </a>
              }
              {val?.vk_url && (
                <a href={val?.vk_url} target="_blank">
                  <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "25px", height: "25px" }}
                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/VK_Compact_Logo_%282021-present%29.svg/1024px-VK_Compact_Logo_%282021-present%29.svg.png"
                    }/>
                </a>
              )}
            </Space>

          </div>
        </div>
      ),
    },
    {
      title: "Стикер",
      dataIndex: "sticker_info",
      key: "sticker_info",
      sorter: false,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val,record) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space>
            {val?.part_a}
            {
              val?.part_b && <span style={{ marginLeft: "4px", padding: "4px", background: "black", color: "white" }}>
                {val?.part_b}
              </span>
            }


          </Space>
          <Space>
            <ReloadOutlined  onClick={() => handleUpdateSticker(record)}  />
            <DownloadOutlined disabled={isDisabledDownloadButton}  onClick={() => handleDownloadSticker(record?.order_id)} />
            <Popconfirm
              title="Подтвердите удаление"
              onConfirm={() => stockFBSRemoveSticker(record?.order_id)}
              okText="Удалить"
              cancelText="Отмена"
            >
              <DeleteOutlined />
            </Popconfirm>
            <PrinterOutlined disabled={isDisabledDownloadButton} onClick={() => handleDownloadSticker(record?.order_id,false)} />
          </Space>
        </Space>

      )
    },
    {
      title: "Этикетки",
      dataIndex: "label",
      key: "label",
      sorter: false,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val,record) => (
        <Tooltip placement="topLeft" title={val || "Этикетка"}>
          <Space>
            {val || "Этикетка"}
            <DownloadOutlined disabled={isDisabledDownloadButton} onClick={() => handleDownloadLabel(record.order_id)} />
            <PrinterOutlined disabled={isDisabledDownloadButton}  onClick={() => handleDownloadLabel(record.order_id,false)} />
          </Space>
        </Tooltip>
      )
    },
    {
      title: "Дата доставки",
      dataIndex: "delivery_date",
      key: "delivery_date",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Остатки",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 200,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Поставка",
      dataIndex: "supplies_info",
      key: "supplies_info",
      sorter: false,
      ellipsis: {
        showTitle: false
      },
      width: 300,
      render: (val, record) => (
        <AutoWidthSelect
          value={val?.current_supply?.name}
          options={[
            ...(val?.all_supply || []),
            { id: -1, name: "Создать новую" }
          ]}
          placeholder={"Выберите поставку"}
          onChange={(value) => {
            if (value === -1) {
              handleCreateNewSupplier(record)
              return
            } else {
              handleSupplyChange(record.id, value)
            }
          }}
        />
      )
    },
    {
      title: "Кто закрыл?",
      dataIndex: "closed_by",
      key: "closed_by",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата сборки",
      dataIndex: "assembly_date",
      key: "assembly_date",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата на МП",
      dataIndex: "arrival_date",
      key: "arrival_date",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Время с момента заказа",
      dataIndex: "time_since_order",
      key: "time_since_order",
      sorter: false,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => {
        return (
          <Tooltip placement="topLeft" title={val?.value}>
            <strong style={{ color: `#${val?.color}` }}>
              {val?.value}
            </strong>
          </Tooltip>
        )
      }
    },
    {
      title: "Действия",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (val, record) => {
        const buttons = []
        const { status_info } = record
        const buttonStyle = {
          backgroundColor: isActionLoading ? "gray" : "#00a2e8",
          borderRadius: "50%",
          color: "#fff"
        }
        if (status_info?.id === OrderStatus.New) {
          buttons.push(
            <Tooltip title="На сборке" key="build">
              <Button
                disabled={isActionLoading}
                loading={isActionLoading}
                onClick={() => handleAction(OrderStatus.Build, {
                  order_ids: [ record?.order_id ],
                  marketplace_id: record?.marketplace_id
                })}
                style={buttonStyle}
                className={"blueButton"}
                type="primary"
                shape="circle"
                icon={<ShoppingCartOutlined/>}
              />
            </Tooltip>
          )
          buttons.push(
            <Tooltip title="Собран" key="collected">
              <Button
                disabled={isActionLoading}
                loading={isActionLoading}
                onClick={() => handleAction(OrderStatus.Collected, {
                  order_ids: [ record?.order_id ],
                  marketplace_id: record?.marketplace_id
                })}
                style={buttonStyle}
                className={"blueButton"}
                type="primary"
                shape="circle"
                icon={<CarOutlined/>}
              />
            </Tooltip>
          )
          buttons.push(
            <Popconfirm
              title="Подтверждаете отмену?"
              onConfirm={() => handleAction(OrderStatus.Cancel, {
                order_ids: [ record?.order_id ],
                marketplace_id: record?.marketplace_id
              })}
              okText="Да"
              cancelText="Отмена"
            >
              <Tooltip title="Отменить заказ" key="cancel">
                <Button
                  disabled={isActionLoading}
                  loading={isActionLoading}
                  className={"blueButton"}
                  style={buttonStyle}
                  type="primary"
                  shape="circle"
                  icon={<MinusCircleOutlined/>}
                />
              </Tooltip>
            </Popconfirm>
          )
        } else if (status_info?.id === OrderStatus.Build) {
          buttons.push(
            <Tooltip title="Собран" key="collected">
              <Button
                disabled={isActionLoading}
                loading={isActionLoading}
                style={buttonStyle}
                onClick={() => handleAction(OrderStatus.Collected, {
                  order_ids: [ record?.order_id ],
                  marketplace_id: record?.marketplace_id
                })}
                type="primary"
                shape="circle"
                icon={<CarOutlined />}
              />
            </Tooltip>
          )

          buttons.push(
            <Popconfirm
              title="Подтверждаете отмену?"
              onConfirm={() => handleAction(OrderStatus.Cancel, {
                order_ids: [ record?.order_id ],
                marketplace_id: record?.marketplace_id
              })}
              okText="Да"
              cancelText="Отмена"
            >
              <Tooltip title="Отменить заказ" key="cancel">
                <Button
                  className={"blueButton"}
                  disabled={isActionLoading}
                  loading={isActionLoading}
                  type="primary"
                  shape="circle"
                  style={buttonStyle}
                  icon={<MinusCircleOutlined/>}
                />
              </Tooltip>
            </Popconfirm>
          )
        } else if (status_info?.id === OrderStatus.Collected || status_info?.id === OrderStatus.Cancel) {
          buttons.push(
            <Tooltip title="Вернуть заказ в новые" key="returnToNew">
              <Button
                className={"blueButton"}
                disabled={isActionLoading}
                loading={isActionLoading}
                onClick={() => handleAction(OrderStatus.New, {
                  order_ids: [ record?.order_id ],
                  marketplace_id: record?.marketplace_id
                })}
                type="primary"
                style={buttonStyle}
                shape="circle"
                icon={<UndoOutlined/>}
              />
            </Tooltip>
          )
        }

        return <Space>{buttons}</Space>
      },
    },
  ]


  const [ columns,setColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedFBSColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...StockFBSColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return StockFBSColumns
  })


  const connectWebSocket = (attempt=1) => {
    const maxAttempts = 3
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["import-fbs-orders"]?.status === true) {
            stockFBSGetImportResults()
            stockFBSGetSuppliersImportResults()
            setIsSocket(true)
            setStockFBSUpdate(true)
          }
          if (updates?.["update-supplies"]?.status === true) {
            stockFBSGetSuppliersImportResults()
            setIsSocket(true)
          }
          if (updates?.["update-orders"]?.status === true) {
            setStockFBSUpdate(true)
            setIsSocket(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }
  }

  useEffect(() => {
    stockFBSGetImportResults()
    stockFBSGetSuppliersImportResults()
    stockFBSGetCompanies()
    stockFBSGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters
    })
    connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      stockFBSGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      },isSocket)
      setStockFBSUpdate(false)
    }
  }, [ isUpdate,isSocket ])

  const handleImportOrder = async (event:React.MouseEventHandler<HTMLAnchorElement>) => {
    if((event as {target?:{className?:string}})?.target?.className !== "action"){
      return
    }
    if(partnersState.values.length === 0){
      showErrorMessage("Выберите компании для которых требуется импорт.")
      return
    }
    try{
      const data = {
        partner_ids:partnersState.values,
        type:partnersState.type
      }
      await stockFBSMarketApi.addImportTask(data)
      showSuccessMessage("Задача на импорт успешно добавлена.")
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при отправке заказов на импорт")
    }
  }

  const handleImportExcel = async () => {
    try{
      await stockFBSMarketApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }
  const checkAndCloseSupplier = async (supplier_id:number) => {
    try{
      const response = await stockFBSMarketApi.checkAndCloseSupplier(supplier_id)
      if(response.data === "Success"){
        window.open(`${routeNames.stockSuppliers}/${supplier_id}`, "_blank")
      } else{
        setCheckSupplierState({
          visible:true,
          id:supplier_id,
          msg:response?.data
        })
      }
    }catch(err:any){
      handleResponseError(dispatch,err)
    }
  }

  const isImportBtnDisabled = () => {
    return !importResults?.status
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed:"left"
  } as TableRowSelection<any>
  const isNotSelected = selectedRowKeys?.length === 0
  const isNotSelectedStatus = !filters?.status_id
  const isDisabledToBuild = ![ 1,3 ].includes(filters?.status_id) || isNotSelectedStatus
  const isDisabledToCollected = ![ 1,2 ].includes(filters?.status_id) || isNotSelectedStatus
  const isDisabledToNew = [ 1,2 ].includes(filters?.status_id) || (filters?.status_id === 3 && filters?.not_collected) || isNotSelectedStatus
  const isTransferToDeliveryDisabled = ![ 1,2,3 ].includes(filters?.status_id) || !filters?.partner_id || isNotSelectedStatus || !transferSuppliers

  const handleAction = async (action: number | string,params?:{order_ids:number[],marketplace_id:number}) => {
    try{
      setIsActionLoading(true)
      const values = {
        status_id:action as OrderStatus,
        marketplace_id:params?.marketplace_id || filters?.marketplace_id,
        order_ids:params?.order_ids || selectedRowKeys,
        ...(isNotSelected && !params?.order_ids && { filters })
      } as UpdateOrderStatus
      await stockFBSMarketApi.updateStatus(values)
      showSuccessMessage("Статус успешно обновлен")
      //setStockFBSUpdate(true)
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      setIsActionLoading(false)
    }
  }
  const handleIconAction = async (action: "printLabel" | "printSticker" | "updateOrders") => {
    try{
      setIsActionLoading(true)
      const values = {
        ...(!isNotSelected && { order_ids:selectedRowKeys }),
        ...(isNotSelected && { filters })
      } as PrintLabel
      await stockFBSMarketApi[action](values)
      action === "updateOrders" &&  showSuccessMessage("Статус успешно обновлен")
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      //setStockFBSUpdate(true)
      setIsActionLoading(false)
    }
  }

  const handleTransferToDelivery = async () => {
    try{
      setIsTransferToDeliveryLoading(true)
      const values = {
        supply_id :transferSuppliers,
        marketplace_id:filters?.marketplace_id,
        partner_id :filters?.partner_id,
        ids:selectedRowKeys.map((el)=>itemData.find((item)=>item?.order_id === el)?.order_id)
      } as TransferToDelivery
      await stockFBSMarketApi.transferToDelivery(values)
      showSuccessMessage("Заказы успешно переведены в поставку.")
      setSelectedRowKeys([])
      //setStockFBSUpdate(true)
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      setIsTransferToDeliveryLoading(false)
    }
  }
  useEffect(() => {
    setSelectedRowKeys([])
  }, [ filters ])

  useEffect(() => {
    setTransferSuppliers(null)
  }, [ filtersFromResponse?.supplies_for_transfer ])

  const generateRowClassName = (record: any) => {
    return `row-color-${record.line_color?.replace("#", "")}`
  }

  const dynamicStyles = useMemo(() => {
    const styles: any = {}
    itemData.forEach((item: any) => {
      if (item.line_color) {
        const className = `row-color-${item.line_color?.replace("#", "")}`
        styles[className] = { backgroundColor: `#${item.line_color}` }
      }
    })
    return styles
  }, [ itemData ])

  const StockSuppliersColumns: ColumnsType<any> = [
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      align:"center",
      sorter: (a, b) => a.marketplace_id - b.marketplace_id,
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (val) => (
        <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "28px" }}
          src={marketPlacePictureMap[val]}/>
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Внешний номер",
      dataIndex: "external_id",
      key: "external_id",
      sorter: (a, b) => a.external_id.localeCompare(b.external_id),
      ellipsis: {
        showTitle: false,
      },
      width: 90,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val && <strong style={{ background:"#f3f6fa",padding:"8px" }}>
            {val}
          </strong> }
        </Tooltip>
      ),
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      sorter: (a, b) => a.org.localeCompare(b.org),
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      ellipsis: {
        showTitle: false,
      },
      width: 120,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          <CalendarOutlined style={{ marginRight: "5px" }} />
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Действия",
      key: "operation",
      width: 100,
      render: (val, record) => {
        return <Tooltip placement="topLeft" title={"Проверить и закрыть"}>
          <div style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            <span onClick={()=>checkAndCloseSupplier(record.id)} style={{ color:"rgb(64, 150, 255)",cursor:"pointer" }}>Сохранить закрыть</span>
          </div>
        </Tooltip>
      },
    }
  ]


  return (
    <>
      <style>
        {Object.keys(dynamicStyles).map(className => {
          const style = dynamicStyles[className]
          return `
            .${className} td {
              background-color: ${style.backgroundColor};
            }
            .${className} .ant-table-cell-fix-left, 
            .${className} .ant-table-cell-fix-right {
              background-color: ${style.backgroundColor};
            }
          `
        })}
      </style>
      <MainLayout pageTitle="Заказы на отгрузку">

        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title="Заказы на отгрузку"
            extra={
              <div>
              </div>
            }
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px" }}>
            <Button
              type="primary"
              className={"blueButton"}
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0,
                background:"#00a2e8"
              }}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ИМПОРТ ЗАКАЗОВ
              </span>

              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                borderLeft: "1px solid white",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(1, values)
                }}
              />
            </Button>
            <Button
              type="primary"
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0 , background:"#00a2e8" }}
              className={"blueButton"}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ИМПОРТ С ОБНОВЛЕНИЕМ ОСТАТКОВ
              </span>
              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                borderLeft: "1px solid white",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(2, values)
                }}
              />
            </Button>
            <Button
              type="primary"
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0 , background:"#00a2e8" }}
              className={"blueButton"}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ПЕРЕЗАГРУЗИТЬ ЗАКАЗЫ ЗА НЕДЕЛЮ
              </span>
              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                borderLeft: "1px solid white",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(3, values)
                }}
              />
            </Button>
          </div>
          <Accordion title={"Результаты импорта"} isVisible={isVisible} toggleVisibility={toggleVisibility}>
            {importResults?.items?.map((result) => (
              <ImportResultAlert
                key={result.marketplace_id}
                marketplace_id={result.marketplace_id}
                message={result.msg}
                color={result.color}
              />
            ))}
          </Accordion>
          <Accordion title={"Поставки"} isVisible={isSuppliesVisible} toggleVisibility={()=>setIsSuppliesVisible(!isSuppliesVisible)}>
            <Table
              columns={StockSuppliersColumns}
              dataSource={suppliersResults}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
              }}
              rowKey={"id"}
              size="small"
              bordered
            />
          </Accordion>
          <div className="page-content" style={{ paddingTop:0 }}>
            <Collapse>
              <Collapse.Panel header="Фильтр заказов" key="1">
                <FilterTable
                  isLoading={isLoadingLists || isLoading}
                  setFilters={setFilters}
                  sorting={sorting}
                />
              </Collapse.Panel>
            </Collapse>

            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br /> Статус сменится у всех заказов <br /> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(1)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы в НОВЫЕ. В Новые можно вернуть, только заказы со статусами НА СБОРКЕ или СОБРАН">
                  <Button
                    disabled={isDisabledToNew}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(1) : undefined}
                    style={{ ...buttonStyle, borderRadius: "4px 0 0 4px", ...(isDisabledToNew && { backgroundColor: "gray" }) }}
                  >
                      НОВЫЙ
                  </Button>
                </Tooltip>
              </Popconfirm>

              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br /> Статус сменится у всех заказов <br /> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(2)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы НА СБОРКУ. На сборку можно перевести, только заказы со статусами НОВЫЙ или СОБРАН">
                  <Button
                    disabled={isDisabledToBuild}
                    style={{
                      ...buttonStyle,
                      borderRadius: 0, ...(isDisabledToBuild && { backgroundColor: "gray" })
                    }}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(2) : undefined}
                  >
                      НА СБОРКЕ
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br /> Статус сменится у всех заказов <br /> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(3)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы в СОБРАН. В собран можно перевести, только заказы со статусами НОВЫЙ или НА СБОРКЕ">
                  <Button
                    disabled={isDisabledToCollected}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(3) : undefined}
                    style={{ ...buttonStyle, borderRadius: 0, ...(isDisabledToCollected && { backgroundColor: "gray" }) }}
                  >
                      СОБРАН
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Печать стикеров для всех выбранных заказов">
                <Button
                  disabled={isActionLoading}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: 0,
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8",
                  }}
                  onClick={() => handleIconAction("printSticker")}
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
              <Tooltip title="Печать этикеток для всех выбранных заказов">
                <Button
                  disabled={isActionLoading}
                  onClick={() => handleIconAction("printLabel")}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: 0,
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8",
                  }}
                  icon={<FilePdfOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Обновить статусы и стикеры заказов, там где их нет">
                <Button
                  disabled={isActionLoading}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: "0 4px 4px 0",
                    borderLeft: "none",
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8"
                  }}
                  onClick={() => handleIconAction("updateOrders")}
                  icon={<ReloadOutlined/>}
                />
              </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <span style={{ fontWeight: 600, fontSize: 16 }}>ПЕРЕНЕСТИ В ПОСТАВКУ  <Tooltip
                  placement="bottom"
                  title={
                    <div
                      className="page-title-tooltip-text"
                      dangerouslySetInnerHTML={{ __html: sendToSuppliesText }}
                    ></div>
                  }
                  color="#fff"
                  zIndex={9999}
                  trigger="click"
                  overlayClassName="page-title-tooltip"
                >
                  <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>
                </Tooltip></span>
                <div style={{ display: "flex", columnGap: "16px", alignItems: "center", marginTop: "10px" }}>
                  <AutoWidthSelect
                    value={transferSuppliers as unknown as undefined}
                    options={[ ...(filtersFromResponse?.supplies_for_transfer || []), {
                      id: -1,
                      name: "Создать новую",
                    } ]}
                    placeholder={"Выберите поставку"}
                    style={{
                      minWidth: "180px",
                    }}
                    onChange={(value) => {
                      if (value === -1) {
                        const values = {
                          id: null,
                          partner_id: filters?.partner_id,
                          marketplace_id: filters?.marketplace_id
                        }
                        if (!filters?.partner_id) {
                          showErrorMessage("Выберите партнера, чтобы продолжить.")
                          setTransferSuppliers(null)
                          return
                        }
                        handleCreateNewSupplier(values)
                        setTransferSuppliers(null)
                        return
                      } else {
                        setTransferSuppliers(value)
                      }
                    }}
                  />
                  <Tooltip
                    placement="bottom"
                    title={
                      <div
                        className="page-title-tooltip-text"
                        dangerouslySetInnerHTML={{ __html: "Выберете обязательные фильтры: организация, маркетплейс, статус НОВЫЙ, НА СБОРКЕ или СОБРАН." }}
                      ></div>
                    }
                    color="#fff"
                    zIndex={9999}
                    trigger="hover"
                    overlayClassName="page-title-tooltip"
                  >
                    <Button
                      style={{
                        ...iconButtonStyle,
                        width: "unset",
                        height: "unset",
                        ...((isTransferToDeliveryDisabled || isTransferToDeliveryLoading) && { backgroundColor: "gray" })
                      }}
                      type={"primary"}
                      disabled={isTransferToDeliveryDisabled || isTransferToDeliveryLoading}
                      onClick={handleTransferToDelivery}>Перенести</Button>
                  </Tooltip>
                </div>
              </div>
              <Tooltip title="Выберите заказы для экспорта.">
                <Button
                  type="primary"
                  icon={<FileExcelOutlined/>}
                  onClick={handleImportExcel}
                  style={{
                    backgroundColor: "#28a745",
                    borderColor: "#28a745",
                    borderRadius: "4px",
                    color: "#fff",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                    СКАЧАТЬ В EXCEL
                </Button>
              </Tooltip>

            </div>

            <Table
              rowSelection={rowSelection}
              title={() => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={() => setIsManageColumnsVisible(true)}>
                    <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}/>
                          СТОЛБЦЫ</Button>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }} onClick={() => {
                      setColumns(StockFBSColumns), showSuccessMessage("Порядок успешно возвращен."), localStorage.setItem("savedFBSColumns", JSON.stringify(StockFBSColumns))
                    }}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
                  </div>
                </div>

              )}
              columns={columns}
              dataSource={itemData}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
              }}
              scroll={{ x: 1300,y:600 }}

              pagination={{
                position: [ "bottomLeft" ],
                showSizeChanger: true,
                pageSizeOptions: [ "10", "25", "50", "100" ],
                defaultCurrent: 1,
                defaultPageSize: 10,
                current: pagesData?.currentPage || 1,
                total: pagesData.total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} из ${total} карточек`
              }}
              rowClassName={generateRowClassName}
              rowKey={"order_id"}
              onChange={(pagination, tableFilters, sorting: any) => {
                const currentSort: { [x: string]: string } = sorting?.order
                  ? {
                    [sorting.columnKey]:
                              sorting.order === "ascend" ? "ASC" : "DESC"
                  }
                  : { created_at: "DESC" }

                setSorting(currentSort)
                stockFBSGetList({
                  page: pagination.current,
                  pageSize: pagination.pageSize,
                  sorting: currentSort,
                  filters: filters
                })
              }}
              size="small"
              bordered
            />
          </div>
          {
            isManageColumnsVisible &&
                <ColumnSettings setVisible={setIsManageColumnsVisible}
                  defaultColumns={StockFBSColumns}
                  columns={columns}
                  setColumns={setColumns}/>
          }
          {
            addSupplierState.visible && <AddSupplier addState={addSupplierState} setAddState={setAddSupplierState}/>
          }
          {
            checkSupplierState.visible && <SuppliersModal state={checkSupplierState} setState={setCheckSupplierState} />
          }
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

const iconButtonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const sendToSuppliesText = "Выберете заказы, которые хотите массово добавить в поставку на маркетплейсе. Сменить поставку можно только для заказов со статусами НОВЫЙ, НА СБОРКЕ или СОБРАН."


export default Sbermarket
