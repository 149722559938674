import React, { FC, useEffect, useMemo, useState } from "react"

import {
  CarOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  ShoppingCartOutlined, UndoOutlined
} from "@ant-design/icons"
import { Button, Collapse, Space, Table, Tooltip, Image, Popconfirm, Form, Switch } from "antd"
import { ColumnsType } from "antd/es/table"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import AddSupplier from "./components/AddSupplier"
import FilterTable from "./components/Filter"
import { stockSuppliersApi } from "../../common/api/endpoints/stockSuppliersApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import Accordion from "../../common/components/accordion/Accordion"
import ColorTag from "../../common/components/color-tag/ColorTag"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import ImportResultAlert from "../../common/components/import-result-alert/ImportResultAlert"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import AutoWidthSelect from "../../common/components/selects/AutoWidthSelect"
import { defaultStockSuppliersFilters } from "../../common/constants/filters"
import { routeNames } from "../../common/constants/routes"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { TAppDispatch } from "../../redux/store"
import { OrderStatus, PrintLabel, TransferToDelivery, UpdateOrderStatus } from "../../types/api"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

const StockSuppliers: FC = () => {
  const [ filters, setFilters ] = useState<any>(defaultStockSuppliersFilters)
  const [ sorting, setSorting ] = useState<{[x: string]: string}>({
    created_at: "DESC"
  })
  const { filters: filtersFromResponse } = useTypedSelector((state) => state.stockSuppliers)

  const [ isSocket, setIsSocket ] = useState(false)

  const { isLoading,
    isUpdate,
    itemData,
    pagesData,
    companies,
    importResults
  } =
        useTypedSelector((state) => state.stockSuppliers)
  const {
    stockSuppliersGetList,
    stockSuppliersGetImportResults,
    setStockSuppliersUpdate,
    stockSuppliersGetCompanies,
    stockSuppliersUpdateSticker,
    stockSuppliersUpdateSupply,
    stockSupplierRemoveSupplier
  } =
        useActions()
  const [ activeCards, setActiveCards ] = useState<any>({
    not_collected: false,
    no_label: false,
    error_on_marketplace: false
  })
  const dispatch = useDispatch()
  const [ partner_ids  ,setPartnerIds ] = useState<number[]>([])
  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ addSupplierState,setAddSupplierState ] = useState<{visible: boolean,id: null | number | undefined , partner_id: null | number | undefined,name: string | undefined,marketplace_id:number | null | undefined}>({ visible:false,id:null,partner_id:null,name:"",marketplace_id:null })
  const handlePartnerIdsChange = (type:number,values:number[]) => {
    if(type === 1){
      setPartnerIds(values)
    }else {
      setPartnerIds([])
    }
  }
  const [ isImportLoading,setIsImportLoading ] = useState(false)
  const [ transferSuppliers,setTransferSuppliers ] = useState<number | null>(null)
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<React.Key[]>([])
  const handleDownloadSticker = async (order_id:number) => {
    try{
      await stockSuppliersApi.downloadSticker(order_id)
    }catch(err){
      showErrorMessage("Произошла ошибка, повторите попытку позже.")
      console.log(err)
    }
  }
  const handleDownloadLabel = async (order_id:number) => {
    try{
      await stockSuppliersApi.downloadLabel(order_id)
    }catch (error:any) {
      handleResponseError(dispatch, error)
    }
  }
  const handleDownloadExcel = async () => {
    try{
      await stockSuppliersApi.downloadExcel(filters)
    }catch(err:any){
      handleResponseError(dispatch,err)
      console.log(err)
    }
  }
  const handleUpdateSticker = async (record:{id:number,order_id:number,marketplace_id:number,partner_id:number}) => {
    try{
      const data = {
        id:record?.id,
        order_id:record?.order_id,
        partner_id:record?.partner_id,
        marketplace_id:record?.marketplace_id
      }
      stockSuppliersUpdateSticker(data)
    }catch(err){
      console.log(err)
    }
  }
  const handleCreateNewSupplier = (record:{id?:number | null,order_id?:number | null,marketplace_id?:number | null,partner_id?:number | null}) => {
    setAddSupplierState({
      visible:true,
      id:record?.id,
      partner_id:record?.partner_id,
      marketplace_id:record?.marketplace_id,
      name:""
    })
  }
  const handleSupplyChange = (id:number,supply_id:number) => {
    stockSuppliersUpdateSupply({
      id,
      supply_id
    })
  }


  const StockSuppliersColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status_info",
      key: "status_info",
      sorter: true,
      align:"center",
      width: 220,
      render: (val) => {
        const color = `#${val?.color}` || "red"
        return <ColorTag color={color}><span style={{ color:"white",fontWeight:"600",padding:"12px",fontSize:"14px" }}>{val.name}</span></ColorTag>
      }
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      align:"center",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "28px" }}
          src={marketPlacePictureMap[val]}/>
      ),
    },
    {
      title: "Дата поставки",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val,record) => (
        <Tooltip placement="topLeft" title={val}>
          <Link to={`${routeNames.stockSuppliers}/${record.id}`} target={"_blank"} style={{
            color:"rgb(64, 150, 255)"
          }}>
            {val}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Короб",
      dataIndex: "box",
      key: "box",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Склад",
      dataIndex: "stock",
      key: "stock",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      ellipsis: {
        showTitle: false
      },
      width: 150,
      sorter: true,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Внешний номер",
      dataIndex: "external_id",
      key: "external_id",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          <strong>
            {val}
          </strong>
        </Tooltip>
      ),
    },
    {
      title: "Действия",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ display:"flex",justifyContent:"center",alignItems:"center",columnGap:"10px",rowGap:"10px",flexWrap:"wrap" }}>
            <Tooltip placement="topLeft" title={"Скачать этикетку поставки"}>
              <div style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
                <PrinterOutlined onClick={() => handleDownloadLabel(record.id)} />
              </div>
            </Tooltip>
            <Popconfirm
              title="Подтвердите удаление"
              onConfirm={() => stockSupplierRemoveSupplier(record?.id)}
              okText="Удалить"
              cancelText="Отмена"
            >
              <DeleteOutlined />
            </Popconfirm>
          </div>
        )
      },
    }
  ]


  const [ columns,setColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedSuppliersColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...StockSuppliersColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return StockSuppliersColumns
  })



  useEffect(() => {
    stockSuppliersGetImportResults()
    stockSuppliersGetCompanies()
    stockSuppliersGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters
    })
    // connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      stockSuppliersGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      },isSocket)
      setStockSuppliersUpdate(false)
    }
  }, [ isUpdate,isSocket ])

  const handleImportOrder = async (event:React.MouseEventHandler<HTMLAnchorElement>) => {
    if((event as {target?:{className?:string}})?.target?.className !== "action"){
      return
    }
    if(partner_ids.length === 0){
      showErrorMessage("Выберите компанию для которой требуется импорт.")
      return
    }
    try{
      setIsImportLoading(true)
      const data = {
        partner_id:partner_ids,
      }
      await stockSuppliersApi.addImportTask(data)
      stockSuppliersGetImportResults()
      showSuccessMessage("Поставки загружены.")
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при отправке заказов на импорт")
    }finally{
      setStockSuppliersUpdate(true)
      setIsImportLoading(false)
    }
  }

  const handleImportExcel = async () => {
    try{
      await stockSuppliersApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
      stockSuppliersGetImportResults()
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  useEffect(() => {
    setSelectedRowKeys([])
  }, [ filters ])

  useEffect(() => {
    setTransferSuppliers(null)
  }, [ filtersFromResponse?.supplies_for_transfer ])

  const generateRowClassName = (record: any) => {
    return `row-color-${record.line_color?.replace("#", "")}`
  }

  const dynamicStyles = useMemo(() => {
    const styles: any = {}
    itemData.forEach((item: any) => {
      if (item.line_color) {
        const className = `row-color-${item.line_color?.replace("#", "")}`
        styles[className] = { backgroundColor: `#${item.line_color}` }
      }
    })
    return styles
  }, [ itemData ])

  const handleCardClick = (doneValue: number) => {
    setActiveCards((prevState: any) => ({
      ...prevState,
      done: doneValue,
    }))
    setFilters((prev:any)=>({ ...prev,done:doneValue }))
    setStockSuppliersUpdate(true)
  }


  return (
    <>
      <style>
        {Object.keys(dynamicStyles).map(className => {
          const style = dynamicStyles[className]
          return `
            .${className} td {
              background-color: ${style.backgroundColor};
            }
            .${className} .ant-table-cell-fix-left, 
            .${className} .ant-table-cell-fix-right {
              background-color: ${style.backgroundColor};
            }
          `
        })}
      </style>
      <MainLayout pageTitle="Поставки">

        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title="Поставки"
            extra={
              <div>
              </div>
            }
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px" }}>
            <Button
              type="primary"
              className={"blueButton"}
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportLoading}
              loading={isLoading}
              style={{ paddingRight: 0 ,
                background:"#00a2e8"
              }}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ИМПОРТИРОВАТЬ ПОСТАВКИ FBS WILDBERRIES
              </span>

              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                borderLeft: "1px solid white",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partner_ids}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(1, values)
                }}
              />
            </Button>
          </div>
          <div className="page-content">

            <div
              style={{
                marginLeft: "-8px",
                display: "flex",
                columnGap: "20px",
                alignItems: "center",
                rowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <Form.Item style={{ width: "max-content" }} name="not_collected" valuePropName="checked">
                <span style={{ marginRight: "10px" }}>Только актуальные поставки</span>
                <Switch
                  checked={activeCards.done === 0}
                  onChange={() => handleCardClick(activeCards.done === 0 ? 1 : 0)}
                />
              </Form.Item>
              <Form.Item style={{ width: "max-content" }} name="no_label" valuePropName="checked">
                <span style={{ marginRight: "10px" }}>Удаленные поставки</span>
                <Switch
                  checked={activeCards.done === 2}
                  onChange={() => handleCardClick(activeCards.done === 2 ? 1 : 2)}
                />
              </Form.Item>
            </div>


            <Table
              title={() => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={() => setIsManageColumnsVisible(true)}>
                    <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}/>
                        СТОЛБЦЫ</Button>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }} onClick={() => {
                      setColumns(StockSuppliersColumns), showSuccessMessage("Порядок успешно возвращен."), localStorage.setItem("savedSuppliersColumns", JSON.stringify(StockSuppliersColumns))
                    }}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
                  </div>
                </div>

              )}
              columns={columns}
              dataSource={itemData}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
              }}
              scroll={{ x: 1300 }}
              pagination={{
                position: [ "bottomLeft" ],
                showSizeChanger: true,
                pageSizeOptions: [ "10", "25", "50", "100" ],
                defaultCurrent: 1,
                defaultPageSize: 10,
                current: pagesData?.currentPage || 1,
                total: pagesData.total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} из ${total} карточек`
              }}
              rowClassName={generateRowClassName}
              rowKey={"order_id"}
              onChange={(pagination, tableFilters, sorting: any) => {
                const currentSort: { [x: string]: string } = sorting?.order
                  ? {
                    [sorting.columnKey]:
                            sorting.order === "ascend" ? "ASC" : "DESC"
                  }
                  : { created_at: "DESC" }

                setSorting(currentSort)
                stockSuppliersGetList({
                  page: pagination.current,
                  pageSize: pagination.pageSize,
                  sorting: currentSort,
                  filters: filters
                })
              }}
              size="small"
              bordered
            />
          </div>
          {
            isManageColumnsVisible &&
              <ColumnSettings setVisible={setIsManageColumnsVisible}
                defaultColumns={StockSuppliersColumns}
                columns={columns}
                setColumns={setColumns}/>
          }
          {
            addSupplierState.visible && <AddSupplier addState={addSupplierState} setAddState={setAddSupplierState}/>
          }
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

const iconButtonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const sendToSuppliesText = "Выберете заказы, которые хотите массово добавить в поставку на маркетплейсе. Сменить поставку можно только для заказов со статусами НОВЫЙ, НА СБОРКЕ или СОБРАН."


export default StockSuppliers
