import React, { FC, useEffect, useState } from "react"

import { PlusOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, Modal, Row, Select, Space } from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showSuccessMessage } from "../../../utils/ui"


interface Props {
  isOpen: boolean;
  setIsOpen: (v: boolean) => any;
  productId: number | null;
  onSuccess: () => void;
}

const AddModal: FC<Props> = ({ isOpen, setIsOpen, productId, onSuccess }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.dropshipping)
  const { dropshippingAddSupplier,itemGetSuppliersList } = useActions()
  const [ suppliersList, setSuppliersList ] = useState<Array<{ id: string | number; name: string }>>([])
  const [ supplier,setSupplier ] = useState("")
  const [ isSupplierDropdownOpen,setIsSupplierDropdownOpen ] = useState(false)
  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [ isOpen ])
  console.log(suppliersList)

  const handleFinish = async (values: any) => {
    try {
      const isSupplierExist = () => {
        return suppliersList.find((supplier)=>supplier.id == values.name)
      }
      const key = isSupplierExist() ? "id" : "name"
      await dropshippingAddSupplier({ [key]:values?.name, product_id: productId! })
      showSuccessMessage("Поставщик успешно добавлен!")
      setIsOpen(false)
      onSuccess()
    } catch (error) {
      console.log(error)
    }
  }
  const addSupplier = () => {
    form.setFields([
      { name: "name", value: `${supplier}` },
    ])

    setSupplier("")
    setIsSupplierDropdownOpen(false)
  }

  return (
    <Modal
      title="Выбор поставщика"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Выбрать"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
      }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        requiredMark={true}
      >
        <Row gutter={16} style={{ columnGap: "25px", width: "100%" }}>
          <Form.Item
            label="Поставщик"
            name="name"
            rules={[
              {
                required: true,
                message: "Поле обязательное",
              },
            ]}
            style={{
              width:"100%"
            }}
          >
            <Select
              placeholder="Поставщик"
              showSearch
              filterOption={false}
              onSearch={(value) =>
                itemGetSuppliersList(value, setSuppliersList)
              }
              open={isSupplierDropdownOpen}
              onDropdownVisibleChange={(open) => setIsSupplierDropdownOpen(open)}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "5px 8px", marginTop: 5, width: "500px" }}>
                    <Input
                      placeholder="Новый поставщик"
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                    />
                    <Button type="text" icon={<PlusOutlined />} onClick={addSupplier}>
                        Добавить
                    </Button>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            >
              {suppliersList.map((item: { id: string | number; name: string }, index: number) => (
                <Select.Option key={index} value={item.id}>
                  <span>{item.name}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddModal
