export const ADMIN_ROLE = 1
export const SENIOR_MANAGER_ROLE = 2
export const MANAGER_ROLE = 3
export const BUYER_SBERMARKET = 4
export const BUYER_SADOVOD = 5
export const GENERAL_MANAGER = 6
export const COLLECTOR_SCANNER = 7
export const ASSEMBLER = 8

export const roles = {
  ADMIN_ROLE,
  SENIOR_MANAGER_ROLE,
  MANAGER_ROLE,
  BUYER_SBERMARKET,
  BUYER_SADOVOD,
  GENERAL_MANAGER,
  COLLECTOR_SCANNER,
  ASSEMBLER,
}
