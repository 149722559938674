import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ICurrentUser } from "./types"

const initialState: ICurrentUser = {
  id: "",
  name: "",
  username: "",
  role: 0,
  telegram: false
}

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialState,
  reducers: {
    currentUserSetData: (state, action: PayloadAction<ICurrentUser>) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.username = action.payload.username
      state.role = action.payload.role
      state.telegram = action.payload.telegram
    },
    currentUserClearData: (state) => {
      state.id = initialState.id
      state.name = initialState.name
      state.username = initialState.username
      state.role = initialState.role
      state.telegram =initialState.telegram
    }
  }
})

export const currentUserSliceActions = currentUserSlice.actions
export const currentUserSliceReducer = currentUserSlice.reducer
