export type AddCookies = {
    client_token:string
    cookies:string
}
export enum OrderStatus {
    "New"= 1,
    "Build" = 2,
    "Collected" = 3,
    "Cancel" = 6
}
export enum MarketPlace  {
    "WB" = 1,
    "OZON" = 2
}
export type UpdateOrderStatus = {
    status_id:OrderStatus,
    marketplace_id:MarketPlace,
    order_ids?:number[]
    filters?:any
}
export type TransferToDelivery = {
    supply_id :number,
    marketplace_id:MarketPlace,
    partner_id ?:number[]
    ids ?:number[]
}
export type PrintLabel = {
    order_ids?:number[]
    filters?:any
}
