import React, { FC, useEffect, useState } from "react"

import {
  LoadingOutlined, MenuOutlined, ShoppingOutlined
} from "@ant-design/icons"
import { Button, Checkbox, Collapse, Divider, Image, Modal, Select, Space, Table, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { ColumnsType } from "antd/es/table"

import AddCookiesModal from "./components/AddCookiesModal"
import FilterTable from "./components/Filter"
import SbermarketColumns from "./components/SbermarketColumns"
import { sberMarketApi } from "../../common/api/endpoints/sberMarketApi"
import { API_WS_URL } from "../../common/api/urls"
import Accordion from "../../common/components/accordion/Accordion"
import ImportResultAlert from "../../common/components/import-result-alert/ImportResultAlert"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import AdvancedSelect from "../../common/components/selects/AdvancedSelect"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import useLoadingButton from "../../common/hooks/useLoadingButton"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { getAccessToken } from "../../utils/auth"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

const Sbermarket: FC = () => {
  const [ isAddCookieVisible,setIsAddCookieVisible ] = useState(false)
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ sorting, setSorting ] = useState<{[x: string]: string}>({
    delivery_date: "DESC"
  })
  const [ isVisible, setIsVisible ] = useState(true)


  const [ isCheckCookiesLoading,handleCheckCookiesLoading ] = useLoadingButton()
  const { isLoading,
    isLoadingLists,
    isUpdate,
    itemData,
    pagesData,
    companies,
    importResults
  } =
        useTypedSelector((state) => state.sber)
  const {
    sberGetList,
    sberGetImportResults,
    sberGetFilters,
    sberCheckCookies,
    setSberUpdate,
    sberGetCompanies,
  } =
        useActions()
  const [ company_ids  ,setCompanyIds ] = useState<number[]>([])
  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ columns,setColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedSberColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...SbermarketColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return SbermarketColumns
  })


  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["import-sbermarket-orders"]?.status === true) {
            sberGetImportResults()
            setSberUpdate(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }
  }

  useEffect(() => {
    sberGetImportResults()
    sberGetCompanies()
    sberGetFilters()
    sberGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters: {}
    })
    connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      sberGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
      setSberUpdate(false)
    }
  }, [ isUpdate ])

  const handleImportOrder = async () => {
    if(company_ids.length === 0){
      showErrorMessage("Выберите компании для которых требуется импорт.")
      return
    }
    try{
      await sberMarketApi.addImportTask(company_ids)
      //sberGetImportResults()
      showSuccessMessage("Задача на импорт успешно добавлена.")
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при отправке заказов на импорт")
    }
  }
  const isImportBtnDisabled = () => {
    if(company_ids.length === 0) return true
    return !importResults?.status
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <MainLayout pageTitle="Заказы СберМаркет">
      <div className="page-wrapper">
        <PageHead
          titleStyles={{ fontWeight:"600" }}
          title="Заказы СберМаркет"
          extra={
            <div>
            </div>
          }
        />
        <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px" }}>
          <Button
            type="primary"
            onClick={handleImportOrder}
            disabled={isImportBtnDisabled()}
            loading={isLoading}
            className="blueButton"
          >
            {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
            ИМПОРТ ЗАКАЗОВ</Button>
          <AdvancedSelect
            items={companies}
            selectedIds={company_ids}
            setSelectedIds={setCompanyIds}
            placeholder="Компания"
          />
        </div>
        <Accordion title={"Результаты импорта"} isVisible={isVisible} toggleVisibility={toggleVisibility}>
          {importResults?.items?.map((result) => (
            <ImportResultAlert
              key={result.marketplace_id}
              marketplace_id={result.marketplace_id}
              message={result.msg}
              color={result.color}
            />
          ))}
        </Accordion>

        <Button
          style={{ marginLeft: "24px", background:"#00a2e8" }}
          type="primary"
          loading={isCheckCookiesLoading}
          onClick={() => handleCheckCookiesLoading(sberCheckCookies)}
        >
          <ShoppingOutlined style={{ marginLeft: "-5px" }}/>
          Проверить куки
        </Button>
        <Button
          style={{ marginLeft: "24px", background:"#00a2e8" }}
          type="primary"
          loading={isLoadingLists}
          onClick={() => setIsAddCookieVisible(true)}
        >
          <ShoppingOutlined style={{ marginLeft: "-5px" }}/>
          Загрузить куки
        </Button>

        <div className="page-content">
          <Collapse>
            <Collapse.Panel header="Фильтр заказов" key="1">
              <FilterTable
                isLoading={isLoadingLists || isLoading}
                setFilters={setFilters}
                sorting={sorting}
              />
            </Collapse.Panel>
          </Collapse>

          <Table
            title={() => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => setIsManageColumnsVisible(true)}>
                  <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}/>
                      СТОЛБЦЫ</Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }} onClick={() => {
                    setColumns(SbermarketColumns), showSuccessMessage("Порядок успешно возвращен."), localStorage.setItem("savedSberColumns", JSON.stringify(SbermarketColumns))
                  }}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
                </div>
              </div>

            )}
            columns={columns}
            dataSource={itemData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
            }}
            scroll={{ x: 1300 }}
            pagination={{
              position: [ "bottomLeft" ],
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: pagesData?.currentPage || 1,
              total: pagesData.total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} из ${total} карточек`
            }}
            onChange={(pagination, tableFilters, sorting: any) => {
              const currentSort: { [x: string]: string } = sorting?.order
                ? {
                  [sorting.columnKey]:
                          sorting.order === "ascend" ? "ASC" : "DESC"
                }
                : { delivery_date: "DESC" }

              setSorting(currentSort)
              sberGetList({
                page: pagination.current,
                pageSize: pagination.pageSize,
                sorting: currentSort,
                filters: filters
              })
            }}
            size="small"
            bordered
          />
        </div>
        {
          isAddCookieVisible && <AddCookiesModal isOpen={isAddCookieVisible} setIsOpen={setIsAddCookieVisible}/>
        }
        {
          isManageColumnsVisible &&
            <ColumnSettings setVisible={setIsManageColumnsVisible}
              defaultColumns={SbermarketColumns}
              columns={columns}
              setColumns={setColumns}/>
        }
      </div>
    </MainLayout>
  )
}

export default Sbermarket
