import React, { FC } from "react"

import {
  ScheduleOutlined,
  LikeOutlined,
  DislikeOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Space, Card, Statistic, Spin } from "antd"

interface IProps {
  bannerData: any | null
  isLoading: boolean
}

const AdsBanners: FC<IProps> = ({ bannerData, isLoading }) => {
  const cardWidth = 180

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Space direction="horizontal">
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Всего карточек"
            value={bannerData?.total_cards}
            precision={0}
            prefix={<ScheduleOutlined />}
          />
        </Card>
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Новых"
            value={bannerData?.new_cards}
            precision={0}
          />
        </Card>
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Проверены"
            value={bannerData?.verified_cards}
            precision={0}
            prefix={<LikeOutlined />}
          />
        </Card>
        <Card
          bordered
          bodyStyle={{ padding: "10px 20px", width: cardWidth + 100 }}
        >
          <Statistic
            title="Проверены с комментарием"
            value={bannerData?.verified_comment_cards}
            precision={0}
            prefix={<LikeOutlined />}
          />
        </Card>
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Исправлено"
            value={bannerData?.corrected_cards}
            precision={0}
          />
        </Card>
        <Card bordered bodyStyle={{ padding: "10px 20px", width: cardWidth }}>
          <Statistic
            title="Удалены"
            value={bannerData?.delete_cards}
            precision={0}
            prefix={<DislikeOutlined />}
          />
        </Card>
      </Space>
    </Spin>
  )
}

export default AdsBanners
