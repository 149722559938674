import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined, MenuOutlined, ShoppingOutlined
} from "@ant-design/icons"
import { Button, Collapse, Image, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { saveAs } from "file-saver"
import JSZip from "jszip"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import FilterTable from "./components/Filter"
import AdsBanners from "../../common/components/banners/AdsBanners"
import ColorTag from "../../common/components/color-tag/ColorTag"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import ExportTable from "../../common/components/modals/ExportTable"
import PageHead from "../../common/components/page-header/PageHeader"
import {
  successTagColor,
  errorTagColor,
  allTagColors
} from "../../common/constants/colors"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { AddExportTask, ExportData } from "../../redux/slices/export/types"
import { showErrorMessage, showSuccessMessage } from "../../utils/ui"
import {marketPlacePictureMap} from "../../utils/data";

const ItemCards: FC = () => {
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ editId, setEditId ] = useState("")
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ sorting, setSorting ] = useState<{[x: string]: string}>({
    date_card: "DESC"
  })
  const [ dataToExport,setDataToExport ] = useState<AddExportTask>({ name :"",metrics:[],values:{} })
  const { isLoading, isLoadingLists, isUpdate, itemData, pagesData, bannerData } =
    useTypedSelector((state) => state.item)
  const { itemsGetList, itemDelete, itemsGetListsData, setItemUpdate,setItemCardsUpdate } =
    useActions()
  const defaultColumns: ColumnsType<any>  = [
    {
      title: "Фото",
      dataIndex: "primary_photo",
      key: "primary_photo",
      width: 120,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Image alt={"Основное фото"} style={{ width:"60px" }} src={val || "https://brilliant24.ru/files/cat/template_01.png"} />
        </div>
      )
    },
    {
      title: "Артикул цифровой",
      dataIndex: "nm_id",
      key: "nm_id",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Артикул буквенный",
      dataIndex: "nm_name",
      key: "nm_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Название товара",
      dataIndex: "product_name",
      key: "product_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Ссылки",
      dataIndex: "urls",
      key: "urls",
      sorter: false,
      render: (value: { top_url:string | null,vk_url:string | null,wb_url:string | null,ozon_url:string | null }) => {
        return (
          <div style={{ display:"flex",justifyContent:"center",columnGap:"8px" }}>
            {
              value.top_url &&  <Tooltip placement="topLeft" title={"Ссылка на топа"}>
                <a href={value.top_url} target="_blank">
                  <img  alt={"Ссылка на топа"} style={{ borderRadius:"50%",width:"25px" }} src={"https://c0.klipartz.com/pngpicture/795/825/sticker-png-computer-icons-geography-map-map-text-globe.png"} />
                </a>
              </Tooltip>
            }
            {
              value.vk_url &&  <Tooltip placement="topLeft" title={"Ссылка на вконтакте"}>
                <a href={value.vk_url} target="_blank">
                  <img alt={"Вконтакте"} style={{ borderRadius:"50%",width:"25px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/VK_Compact_Logo_%282021-present%29.svg/480px-VK_Compact_Logo_%282021-present%29.svg.png" || "https://brilliant24.ru/files/cat/template_01.png"} />
                </a>
              </Tooltip>
            }
            {
              (value.wb_url || value.ozon_url) &&  <Tooltip placement="topLeft" title={"Ссылка на маркетплейс"}>
                <a href={value.wb_url || value.ozon_url || ""} target="_blank">
                  <img alt={"Маркетплейс"} style={{ borderRadius:"50%",width:"25px" }} src={marketPlacePictureMap[value.wb_url ? 1 : 2]} />
                </a>
              </Tooltip>
            }
          </div>
        )},
      ellipsis: {
        showTitle: false
      },
      width: 150
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Партнёр",
      dataIndex: "partner_name",
      key: "partner_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Баркод",
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Остаток на складе",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} шт`}>
          {val} {val > 0  && "шт"}
        </Tooltip>
      )
    },
    {
      title: "Цена до скидки",
      dataIndex: "price_without_discount",
      key: "price_without_discount",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} руб`}>
          {val} руб
        </Tooltip>
      )
    },
    {
      title: "Цена после скидки без СПП",
      dataIndex: "discount_price_spp",
      key: "discount_price_spp",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} руб`}>
          {val} руб
        </Tooltip>
      )
    },
    {
      title: "Скидка на МП",
      dataIndex: "discount_percentage",
      key: "discount_percentage",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}%`}>
          {val}%
        </Tooltip>
      )
    },
    {
      title: "Кол-во отзывов",
      dataIndex: "review_count",
      key: "review_count",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Рейтинг",
      dataIndex: "rating",
      key: "rating",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Заказали товаров",
      dataIndex: "orders_count",
      key: "orders_count",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Выкупили товаров",
      dataIndex: "buyouts_count",
      key: "buyouts_count",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val}`}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Цена топа на WB",
      dataIndex: "top_price_wb",
      key: "top_price_wb",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} руб`}>
          {val} {!!val && "руб" }
        </Tooltip>
      )
    },
    {
      title: "Цена закупки Садовод",
      dataIndex: "purchase_price_gardener",
      key: "purchase_price_gardener",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} руб`}>
          {val} руб
        </Tooltip>
      )
    },
    {
      title: "Логистика",
      dataIndex: "logistics_wb",
      key: "logistics_wb",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={`${val} руб`}>
          {val} руб
        </Tooltip>
      )
    },
    {
      title: "Размер",
      dataIndex: "product_size",
      key: "product_size",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Поставщик",
      dataIndex: "purveyor_name",
      key: "purveyor_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Телефон поставщика",
      dataIndex: "supplier_phone_number",
      key: "supplier_phone_number",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Сотрудник",
      dataIndex: "user_name",
      key: "user_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Комментарий",
      dataIndex: "manager_comment",
      key: "manager_comment",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Статус",
      dataIndex: "card_status",
      key: "card_status",
      sorter: true,
      width: 220,
      render: (val) => {
        let color = errorTagColor

        switch (val) {
          case "Новая":
            color = successTagColor
            break
          case "Удалена":
            color = errorTagColor
            break
          case "Проверена":
            color = allTagColors[2]
            break
          case "Проверена с комментариями":
            color = allTagColors[3]
            break
          case "Исправлено":
            color = allTagColors[1]
            break

          default:
            color = errorTagColor
        }

        return <ColorTag color={color}>{val}</ColorTag>
      }
    },
    {
      title: "Дата",
      dataIndex: "date_card",
      key: "date_card",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 90,
      render: (value, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditId(record.id)
              setIsEditOpen(true)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить сотрудника?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  itemDelete(record.id, () => {
                    setItemUpdate(true)
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]

  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ isExportOpen,setIsExportOpen ] = useState(false)
  const [ itemsColumns,setItemsColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...defaultColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return defaultColumns
  })
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<React.Key[]>([])

  useEffect(()=>{
    const metrics = itemsColumns.map((el)=>el.key).filter((el)=> el !== "actions") as string []
    setDataToExport((prev)=>({ ...prev,values:filters,metrics }))
  },[ filters,itemsColumns ])
  useEffect(() => {
    itemsGetListsData()
    itemsGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters: {}
    })
  }, [])

  useEffect(() => {
    if (isUpdate) {
      itemsGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
      setItemUpdate(false)
    }
  }, [ isUpdate ])
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    fixed:true,
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const exportSelectedImages = async () => {
    try{
      setItemCardsUpdate(true)
      const zip = new JSZip()
      const selectedItems = itemData.filter(item => selectedRowKeys.includes(item.id))

      for (let index = 0; index < selectedItems.length; index++) {
        const item = selectedItems[index]
        const response = await fetch(item.primary_photo)
        const blob = await response.blob()
        const extension = item.primary_photo.split(".").pop()
        zip.file(`${index + 1}.${extension}`, blob)
      }
      const content = await zip.generateAsync({ type: "blob" })
      saveAs(content, "download.zip")
    }catch(err){
      console.log(err)
      showErrorMessage("Произошла ошибка, повторите попытку позже.")
    }finally{
      setItemCardsUpdate(false)
    }

  }
  useEffect(()=>{
    setSelectedRowKeys([])
  },[pagesData.currentPage])

  return (
    <MainLayout pageTitle="Карточки товаров">
      <div className="page-wrapper">
        <PageHead
          title="Карточки товаров"
          extra={
            <div>
            </div>
          }
        />
        <Button
          style={{ marginLeft:"24px", background:"#00a2e8" }}
          type="primary"
          onClick={() => setIsAddOpen(true)}
          loading={isLoadingLists}
        >
          <ShoppingOutlined style={{ marginLeft:"-5px" }} />
          Добавить товар
        </Button>
        <Button style={{ marginLeft:"20px" }} loading={isLoading} onClick={exportSelectedImages} disabled={!selectedRowKeys.length}>Экспорт выбранных изображений</Button>
        <div style={{ padding: "10px 24px" }}>
          <AdsBanners isLoading={isLoading} bannerData={bannerData} />
        </div>

        <div className="page-content">
          <Collapse>
            <Collapse.Panel header="Фильтр товаров" key="1">
              <FilterTable
                isLoading={isLoadingLists || isLoading}
                setFilters={setFilters}
                sorting={sorting}
              />
            </Collapse.Panel>
          </Collapse>

          <Table
  rowSelection={rowSelection}
  title={() => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
        onClick={() => setIsManageColumnsVisible(true)}
      >
        <MenuOutlined
          style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
        />
        СТОЛБЦЫ
      </Button>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ cursor: "pointer" }} onClick={() => setIsExportOpen(true)}>
          {projectIcons.UilExport()}
        </div>
        <Button
          style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
          onClick={() => {
            setItemsColumns(defaultColumns);
            showSuccessMessage("Порядок успешно возвращен.");
            localStorage.setItem("savedColumns", JSON.stringify(defaultColumns));
          }}
        >
          ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
        </Button>
      </div>
    </div>
  )}
  columns={itemsColumns.map(col => ({
    ...col,
    fixed: col.key === 'actions' ? 'right' : undefined
  }))}
  dataSource={itemData}
  loading={{
    spinning: isLoading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  }}
  scroll={{ x: 1600, y: 'calc(100vh - 200px)' }}
  pagination={{
    position: ["bottomLeft"],
    showSizeChanger: true,
    pageSizeOptions: ["10", "25", "50", "100"],
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: pagesData?.currentPage || 1,
    total: pagesData.total,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} из ${total} карточек`,
  }}
  onChange={(pagination, tableFilters, sorting: any) => {
    const currentSort: { [x: string]: string } = sorting?.order
      ? {
          [sorting.columnKey]:
            sorting.order === "ascend" ? "ASC" : "DESC",
        }
      : { date_card: "DESC" };

    setSorting(currentSort);
    itemsGetList({
      page: pagination.current,
      pageSize: pagination.pageSize,
      sorting: currentSort,
      filters: filters,
    });
  }}
  size="small"
  bordered
/>

          <AddModal
            isOpen={isAddOpen}
            setIsOpen={setIsAddOpen}
            filters={filters}
          />
          <EditModal
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            editId={editId}
            filters={filters}
          />
          {
            isManageColumnsVisible &&
              <ColumnSettings setVisible={setIsManageColumnsVisible}
                defaultColumns={defaultColumns}
                columns={itemsColumns}
                setColumns={setItemsColumns} />
          }
          {
            isExportOpen && <ExportTable dataToExport={dataToExport} setVisible={setIsExportOpen} />
          }
        </div>
      </div>
    </MainLayout>
  )
}

export default ItemCards
