import { dropshippingSliceActions } from "./dropshippingSlice";
import { dropshippingApi } from "../../../common/api/endpoints/DropshippingApi";
import { handleResponseError } from "../../../common/api/middleware";
import { TAppDispatch } from "../../store";
import { AxiosError } from "axios";

export const dropshippingActions = {
  ...dropshippingSliceActions,

  dropshippingGetList: (filters: any,isSocket=false) => async (dispatch: TAppDispatch) => {
    console.log("dropshippingGetList called with filters:", filters);
    !isSocket && dispatch(dropshippingActions.setItemLoading(true));

    try {
      const response = await dropshippingApi.fetchList(filters);
      if (response.status === 200) {
        const data = response.data;
        dispatch(dropshippingActions.setDropshippingData(data.rows.map((el: { id: number }) => ({ ...el, key: el.id }))));
        dispatch(dropshippingActions.setItemPagesData({
          total: data.total || 0,
          totalPages: data.totalPages || 0,
          start: data.start || 1,
          end: data.end || 0,
          currentPage: data.currentPage || 1,
          pageSize: data.pageSize || 10,
        }));
      }
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError);
    } finally {
      !isSocket && dispatch(dropshippingActions.setItemLoading(false));
    }
  },

  dropshippingAddSupplier: (data: { name?: string | number, product_id: number,id?:number }) => async (dispatch: TAppDispatch) => {
    console.log("dropshippingAddSupplier called with data:", data);
    dispatch(dropshippingActions.setItemLoading(true));

    try {
      const response = await dropshippingApi.edit(data);
      if (response.status === 200) {
        dispatch(dropshippingActions.addSupplierSuccess("Success"));
      }
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError);
    } finally {
      dispatch(dropshippingActions.setItemLoading(false));
    }
  },
};
