import React from "react"

import { Button, Image, Space, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { Link } from "react-router-dom"

import { routeNames } from "../../../common/constants/routes"
import { marketPlacePictureMap, vk_image } from "../../../utils/data"

const DropshippingColumns: (props: { setIsAddOpen: (open: boolean) => void; setProductId: (id: number | null) => void }) => ColumnsType<any> = ({
  setIsAddOpen,
  setProductId,
}) => [
  {
    title: "Фото",
    dataIndex: "photo",
    key: "photo",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (val) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          alt={"Основное фото"}
          style={{ width: "60px" }}
          src={val || "https://brilliant24.ru/files/cat/template_01.png"}
        />
      </div>
    ),
  },
  {
    title: "Количество",
    dataIndex: "quantity",
    key: "quantity",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Время с момента заказа",
    dataIndex: "arrival_date",
    key: "arrival_date",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val: any) => (
      <Tooltip placement="topLeft" title={val.value}>
        <strong style={{ color: `#${val.color}` }}>{val.value}</strong>
      </Tooltip>
    ),
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 300,
    render: (val: any, record: any) => {
      return (
        <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
          <div style={{ marginTop: 8 }}>
            {record.suppliers && record.suppliers.length > 0 ? (
              <>
                {record.suppliers.map((supplier: any, index: number) => (
                  <React.Fragment key={supplier.id}>
                    <Link target="_blank" rel="noopener noreferrer" to={`${routeNames.dropshippingSupplier}/${supplier.id}`}>
                      {supplier.name}
                    </Link>
                    {index < record.suppliers.length - 1 && ", "}
                  </React.Fragment>
                ))}
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setProductId(record.product_id)
                    setIsAddOpen(true)
                  }}
                  style={{ marginTop: 8 }}
                >
                  Выбрать поставщика
                </Button>
              </>
            ) : (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  setProductId(record.product_id)
                  setIsAddOpen(true)
                }}
              >
                Выбрать поставщика
              </Button>
            )}
          </div>
        </div>
      )
    },
  },

  {
    title: "Артикул",
    dataIndex: "sku",
    key: "sku",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 225,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Ссылки",
    dataIndex: "links",
    key: "links",
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val: any) => (
      <Space style={{ display: "flex", justifyContent: "center" }}>
        {val.product_url && (
          <a href={val.product_url} target="_blank" rel="noopener noreferrer">
            <img alt="Продукт" src={marketPlacePictureMap[val.marketplace_id]} style={{ borderRadius: "50%", width: "24px", marginRight: "8px", marginLeft:"8px" }} />
          </a>
        )}
        {val.vk_url && (
          <a href={val.vk_url} target="_blank" rel="noopener noreferrer">
            <img alt="VK" style={{ borderRadius: "50%", width: "24px" }} src={vk_image} />
          </a>
        )}
      </Space>
    ),
  },
  {
    title: "Закупочная цена",
    dataIndex: "purchase_price",
    key: "purchase_price",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Штрих-код",
    dataIndex: "barcode",
    key: "barcode",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
]

export default DropshippingColumns
