import React, { FC, useEffect, useState } from "react"

import {
  DatabaseOutlined,
  LoadingOutlined, MenuOutlined, SearchOutlined, ShoppingOutlined
} from "@ant-design/icons"
import { Button, Collapse, Image, Input, Select, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import NumericInput from "./components/NumericInput"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { IItemRow } from "../../redux/slices/prices/types"
import { showSuccessMessage } from "../../utils/ui"
import {marketPlacePictureMap} from "../../utils/data";
interface List {
  id: string | number;
  name: string;
}

interface Lists {
  [key: string]: List;
}

const Prices: FC = () => {
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ editId, setEditId ] = useState("")
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ sorting, setSorting ] = useState<{[x: string]: string}>({
  })
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<React.Key[]>([])
  const { isLoading, isLoadingLists, isUpdate, itemData, pagesData,lists } =
    useTypedSelector((state) => state.price)
  const { priceGetList, priceGetListsData, setPriceUpdate,setPriceData,priceSend } =
    useActions()

  const defaultColumns: ColumnsType<any>  = [
    {
      title: "Фото",
      dataIndex: "primary_photo",
      key: "primary_photo",
      width: 120,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Image alt={"Основное фото"} style={{ width:"60px" }} src={val || "https://brilliant24.ru/files/cat/template_01.png"} />
        </div>
      )
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 110,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <img alt={"Маркетплейс"} style={{ borderRadius:"50%",width:"36px" }} src={marketPlacePictureMap[val as number]} />
        </div>
      )
    },
    {
      title: "Артикул цифровой",
      dataIndex: "nm_id",
      key: "nm_id",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },

    {
      title: "Баркод",
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Название товара",
      dataIndex: "nm_name",
      key: "nm_name",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Цена в БД",
      dataIndex: "price_in_table",
      key: "price_in_table",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val, record) => (
        <NumericInput
          value={val}
          currency={"руб"}
          onChange={(value) => handlePriceChange(Number(value), record.key, "price_in_table")}
        />
      )
    },
    {
      title: "Процент скидки в БД",
      dataIndex: "discount_percentage",
      key: "discount_percentage",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val, record) => (
        <NumericInput
          value={val}
          currency={"%"}
          onChange={(value) => handlePriceChange(Number(value), record.key, "discount_percentage")}
        />
      )
    },
    {
      title: "Цена в БД после скидки",
      dataIndex: "price_in_table_discount",
      key: "price_in_table_discount",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val && "руб"}>
          {val} {val && "руб"}
        </Tooltip>
      )
    },
    {
      title: "Цена МП",
      dataIndex: "price_in_mp",
      key: "price_in_mp",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val, record) => (
        <NumericInput
          value={val}
          currency={"руб"}
          onChange={(value) => handlePriceChange(Number(value), record.key, "price_in_mp")}
        />
      )
    },
    {
      title: "Процент скидки МП",
      dataIndex: "discount_percentage_mp",
      key: "discount_percentage_mp",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val, record) => (
        <NumericInput
          value={val}
          currency={"%"}
          onChange={(value) => handlePriceChange(Number(value), record.key, "discount_percentage_mp")}
        />
      )
    },
    {
      title: "Цена МП после скидки",
      dataIndex: "price_in_mp_discount",
      key: "price_in_mp_discount",
      sorter: true,
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val && "руб"}>
          {val} {val && "руб"}
        </Tooltip>
      )
    },
  ]


  useEffect(() => {
    priceGetListsData()
    priceGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters: {}
    })
  }, [])
  useEffect(()=>{
    priceGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters
    })
  },[ filters ])
  useEffect(() => {
    if (isUpdate) {
      priceGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
      setPriceUpdate(false)
    }
  }, [ isUpdate ])
  const handlePriceChange = (value:number, key:string, field:string) => {
    const updatedData = itemData.map(item => {
      if (item.key === key) {
        return { ...item, [field]: value }
      }
      return item
    })
    setPriceData(updatedData)
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0
  const handleSendRequest = (typeId:number) => {
    const data = itemData.reduce((acc, current) => {
      if (selectedRowKeys.includes(current.key)) {
        acc.push({
          id: current.id,
          marketplace_id: current.marketplace_id,
          partner_id: current.partner_id,
          price_in_table: current.price_in_table,
          price_in_table_discount: current.price_in_table_discount,
          discount_percentage: current.discount_percentage,
          price_in_mp: current.price_in_mp,
          price_in_mp_discount: current.price_in_mp_discount,
          discount_percentage_mp: current.discount_percentage_mp,
          type_id: typeId
        })
      }
      return acc
    }, [] as (IItemRow | {type_id:number})[])
    const onFinish = () => {
      setPriceUpdate(true)
      showSuccessMessage("Данные успешно отправлены")
    }
    priceSend(data,onFinish)
  }
  return (
    <MainLayout pageTitle="Цены">
      <div className="page-wrapper">
        <PageHead
          title="Цены"
          extra={
            <div>
            </div>
          }
        />
        <div className="page-content">
          <div style={{ display:"flex",columnGap:"16px",alignItems:"center",width:"40%",marginTop:"10px" }}>
            <Select style={{ minWidth:"150px" }} placeholder="Маркетплейс"
              showSearch
              allowClear
              onChange={(value)=>setFilters((prev)=>({ ...prev,marketplace_id :[ Number(value === undefined ? 3 : value) ] }))}
              filterOption={(input, option) =>
                option && option.children
                  ? (option.children as unknown as string)
                    .toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {Object.entries(lists as Lists).map((el) =>
                (
                  <Select.Option key={el[1].id} value={el[1].id}>
                    {el[1].name as string}
                  </Select.Option>
                )
              )}
            </Select>
            <Button type={"primary"} className="blueButton" disabled={!hasSelected} onClick={()=>handleSendRequest(1)} icon={<DatabaseOutlined />}>Отправить в БД</Button>
            <Button type={"primary"} className="blueButton" disabled={!hasSelected} onClick={()=>handleSendRequest(2)} icon={projectIcons.marketplaceIcon()}>Отправить на МП</Button>
          </div>

          <Table
            columns={defaultColumns}
            dataSource={itemData}
            rowSelection={rowSelection}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            pagination={{
              position: [ "bottomLeft" ],
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: pagesData?.currentPage || 1,
              total: pagesData.total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} из ${total} карточек`
            }}
            onChange={(pagination, tableFilters, sorting: any) => {
              const currentSort: {[x: string]: string} = sorting?.order
                ? {
                  [sorting.columnKey]:
                      sorting.order === "ascend" ? "ASC" : "DESC"
                }
                : { date_card: "DESC" }

              setSorting(currentSort)
              priceGetList({
                page: pagination.current,
                pageSize: pagination.pageSize,
                sorting: currentSort,
                filters: filters
              })
            }}
            size="small"
            bordered
          />

          <AddModal
            isOpen={isAddOpen}
            setIsOpen={setIsAddOpen}
            filters={filters}
          />
          <EditModal
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            editId={editId}
            filters={filters}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default Prices
