import React, { FC, useEffect, useState, useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, DatePicker } from "antd";
import { useActions } from "../../../common/hooks/useActions";
import { useTypedSelector } from "../../../common/hooks/useTypedSelector";
import { removeObjectEmptyKeys } from "../../../utils/data";

interface Props {
  isLoading: boolean;
  sorting: { [x: string]: string };
  setFilters: (v: any) => any;
}

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [form] = Form.useForm();
  const { pagesData, filtersData } = useTypedSelector((state) => state.purchase);
  const { getPartnerFilterList, getPurchaseList, itemGetSuppliersList } = useActions();

  const [suppliersList, setSuppliersList] = useState<Array<{ id: string | number; name: string }>>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const fetchPartnerFilterList = useCallback(() => {
    if (!isDataLoaded) {
      getPartnerFilterList();
      setIsDataLoaded(true);
    }
  }, [isDataLoaded, getPartnerFilterList]);

  useEffect(() => {
    fetchPartnerFilterList();
  }, [fetchPartnerFilterList]);

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values);
    const data = {
      ...cleanedValues,
      data: cleanedValues.data && {
        start: cleanedValues.data[0],
        end: cleanedValues.data[1],
      },
    };

    setFilters(data);
    getPurchaseList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting as { [key: string]: "ASC" | "DESC"; },
      filters: data,
    });
  };

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <Card style={{ width: "100%", backgroundColor:"rgba(0, 0, 0, 0.02)"}}>
        <Form form={form} onFinish={onSubmitFilter} name="basic">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="data">
                <DatePicker.RangePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="nm_id">
                <Input placeholder="Артикул цифровой ВБ" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="nm_name">
                <Input placeholder="Артикул буквенный ВБ" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="barcode">
                <Input placeholder="Баркод" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="user_id">
                <Select
                  mode="multiple"
                  placeholder="Сотрудник"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {filtersData?.user_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="purveyor_id">
                <Select
                  placeholder="Поставщик"
                  showSearch
                  mode="multiple"
                  filterOption={false}
                  onSearch={(value) =>
                    itemGetSuppliersList(value, setSuppliersList)
                  }
                >
                  {suppliersList.map((item: { id: string | number; name: string }, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      <span>{item.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="partner_id">
                <Select
                  mode={"multiple"}
                  placeholder="Партнеры"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {filtersData?.partner_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="marketplace_id">
                <Select
                  mode={"multiple"}
                  placeholder="Маркетплейс"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {Object.entries(filtersData?.marketplace_list || {}).map(([key, value], index) => (
                    <Select.Option key={index} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Space>
          <Button type="primary" style={{background:"#00a2e8"}} onClick={() => form.submit()}>
            Применить
          </Button>
          <Button
            type="default"
            onClick={() => {
              form.resetFields();
              getPurchaseList({
                page: pagesData.currentPage || 1,
                pageSize: pagesData.pageSize || 10,
                sorting: sorting as { [key: string]: "ASC" | "DESC"; },
                filters: {},
              });
              setFilters({});
            }}
          >
            Сбросить
          </Button>
        </Space>
      </Card>
    </Spin>
  );
};

export default FilterTable;
